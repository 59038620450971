import { useGetCustomer } from '@dtk/query';
import { ErrorState, Spinner } from '@dtk/ui-components';
import { NewPasswordModal } from './NewPasswordModal';

export const NewPasswordModalWrapper = () => {
  const { data, isError, isLoading } = useGetCustomer();

  if (isLoading || !data) {
    return <Spinner />;
  }
  if (isError) {
    return <ErrorState state="tryAgainLater" />;
  }

  const customerData = data?.data;
  const storedCustomerEmailAdress = sessionStorage.getItem('DTK-passwordSet-newlyRegisteredCustomer');
  customerData.hasPortalPassword = customerData?.eMail === storedCustomerEmailAdress || customerData.hasPortalPassword;

  return !customerData.hasPortalPassword ? <NewPasswordModal customerData={customerData} /> : null;
};
