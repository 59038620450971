import { useAppConfig } from '@dtk/config';
import { AuthenticationType } from './AuthenticationCard';
import { useMediaQuery } from '../../hooks';

export type FooterVariant = 'no-password' | 'no-account' | 'account' | 'magic-link' | 'none';

export interface AuthenticationCardFooter {
  variant: FooterVariant;
  setLoginType: (loginType: AuthenticationType) => void;
}

export const AuthenticationCardFooter = ({ variant, setLoginType }: AuthenticationCardFooter) => {
  const { config } = useAppConfig();
  const { largerLg } = useMediaQuery();

  const FooterTextNoAccount = () => (
    <p>
      Sie haben noch kein Konto?{' '}
      <span className="text-cyan hover:text-cyan-900">
        <button onClick={() => setLoginType('registration')}>Jetzt erstellen.</button>
      </span>
    </p>
  );

  const FooterTextAccount = () => (
    <p>
      Sie haben bereits ein Konto?{' '}
      <span className="text-cyan hover:text-cyan-900">
        <button onClick={() => setLoginType('credentials')}>Jetzt anmelden.</button>
      </span>
    </p>
  );

  const FooterTextAccountWithLink = () => (
    <span className="text-cyan hover:text-cyan-900">
      <button onClick={() => setLoginType('magiclink')}>Anmeldelink an meine E-Mail-Adresse senden</button>
    </span>
  );

  return (
    <div className="text-navy space-y-3 pt-3 text-center">
      {variant === 'no-account' && <FooterTextNoAccount />}
      {variant === 'account' && <FooterTextAccount />}
      {variant === 'magic-link' && largerLg && <FooterTextAccountWithLink />}
      {variant !== 'none' && (
        <p className="text-xs">
          Informationen zum Datenschutz finden Sie{' '}
          <a
            className="text-cyan hover:text-cyan-900"
            href={`${config['WEBSITE_SOURCE']}/datenschutz`}
            target="_blank"
            rel="noopener noreferrer"
          >
            hier.
          </a>
        </p>
      )}
    </div>
  );
};
