import { CustomerTask } from './CustomerTask';
import { TooltipColor } from '../Tooltip';
import classNames from 'classnames';
import styles from './CustomerTasks.module.css';

export function CustomerTasks({ tasks, infoIconTooltipColorVariant }: CustomerTasksProps) {
  return (
    <div
      className={classNames(
        'xs:mb-[22px] xs:max-h-[450px] text-navy my-[12px] h-full max-h-[450px] overflow-auto px-[5px] md:max-h-[368px] lg:mb-2',
        styles.scrollbar
      )}
    >
      {tasks?.map((task, index) => (
        <CustomerTask task={task} key={index} infoIconTooltipColorVariant={infoIconTooltipColorVariant} />
      ))}
    </div>
  );
}

export interface CustomerTasksProps {
  tasks: CustomerTask[];
  infoIconTooltipColorVariant: TooltipColor;
}
