import { Button } from '@dtk/ui-components';
import classNames from 'classnames';
import { storyblokEditable } from '@storyblok/react';

const MP_CheckoutBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div
      className="bg-coolGray-50 relative flex w-full flex-row"
      {...storyblokEditable(blok)}
      data-test="mp-checkout"
      data-step-id={blok.gtmId}
    >
      <div className={'flex min-h-screen w-full p-5 md:-mt-20 '}>
        <div className={classNames('mx-auto my-auto  max-w-xl')}>
          <div className="my-10 flex h-auto flex-col items-center justify-center gap-5">
            <h1 className="font-headline text-center text-3xl">
              Vielen Dank, <span className="text-gold font-bold">Max Mustermann</span>!
            </h1>
            <p className="text-center">
              Wir haben Ihren Auftrag erhalten. Sieerhalten Nachricht von uns, sobald ihr persönliches Angebot verfügbar
              ist.
            </p>
            <Button variant={'white'}>
              <span className="text-lg font-bold">Zurück zur Produktübersicht</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MP_CheckoutBlok;
