import { AuthenticationCard, AuthenticationType, validationMessages } from '@dtk/ui-components';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useAuthState } from '../../store';
import { CredentialsFields, CredentialsForm } from './form';

interface CredentialLoginProps {
  setLoginType: (loginType: AuthenticationType) => void;
  email?: string;
}
export function CredentialsDialog({ setLoginType }: CredentialLoginProps) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const authState = useAuthState();

  async function signIn(credentials: CredentialsFields) {
    try {
      const user = await Auth.signIn(credentials.email.toLowerCase(), credentials.password);

      if (user) {
        authState.changed();
      }
    } catch (error) {
      setErrorMessage(validationMessages.error.loginFeedback.text);
    }
  }

  return (
    <AuthenticationCard
      overline="Anmeldung"
      headline="Anmeldung zu Ihrem Kundenportal"
      subtitle="Geben Sie bitte Ihr Passwort ein."
      footerVariant="magic-link"
      setLoginType={setLoginType}
      secondaryButton={{ text: 'Registrieren', loginType: 'registration' }}
    >
      <CredentialsForm
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        onSubmit={(values) => signIn(values)}
        setLoginType={setLoginType}
      />
    </AuthenticationCard>
  );
}
