import { storyblokEditable } from '@storyblok/react';

const CategoryPostBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} className="category-post" data-test="category-post">
      <h1>{blok.title}</h1>
      <h2>{blok.subtitle}</h2>
    </div>
  );
};

export default CategoryPostBlok;
