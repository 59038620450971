import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';

export interface DtkSliderProps extends SliderProps {
  startLabel?: string;
  middleLabel?: string;
  endLabel?: string;
}

export function DtkSlider(props: DtkSliderProps) {
  const { startLabel, middleLabel, endLabel, trackStyle, handleStyle, railStyle, ...restProps } = props;
  const navy400 = '#4873AD';
  const gold = '#AF8232';
  const coolGray200 = '#E5E7EB';

  return (
    <div>
      <Slider
        {...restProps}
        trackStyle={{ backgroundColor: navy400, height: 4 }}
        handleStyle={{
          borderColor: 'white',
          borderWidth: 3,
          height: 26,
          width: 26,
          marginTop: -11,
          opacity: 100,
          backgroundColor: gold,
          boxShadow: '0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.25)',
        }}
        railStyle={{ backgroundColor: coolGray200, height: 4 }}
      />
      {startLabel && (
        <div className="mt-4 flex justify-between">
          {startLabel && <div className="text-coolGray-500 text-sm">{startLabel}</div>}
          {middleLabel && <p className="text-coolGray-500 text-sm">{middleLabel}</p>}
          {endLabel && <div className="text-coolGray-500 text-sm">{endLabel}</div>}
        </div>
      )}
    </div>
  );
}
