import { MailIcon, PhoneIcon } from '@heroicons/react/solid';

import { Button } from './';
import Image from 'next/image';
import classNames from 'classnames';
import { storyBlokLoader } from '../utils/storyblok-loader';

export type ContactCardProps = {
  pictureUrl: string;
  firstname: string;
  lastname: string;
  phone: string;
  eMail: string;
  primaryBgColor?: string;
  secondaryBgColor?: string;
  primaryTextColor?: string;
  svgPrimaryColor?: string;
};

export function ContactCard({
  pictureUrl,
  firstname,
  lastname,
  phone,
  eMail,
  primaryTextColor,
  primaryBgColor,
  secondaryBgColor,
  svgPrimaryColor,
}: ContactCardProps) {
  const openEMail = (mail: string) => {
    window.open(`mailto:${mail}`);
  };

  return (
    <div
      className={classNames(
        'text-navy flex h-full w-full flex-col rounded-lg shadow-lg md:flex-row lg:flex-row',
        secondaryBgColor && `bg-${secondaryBgColor}`
      )}
    >
      <div className="relative flex flex-1 rounded-lg">
        <div className="relative m-4 w-full rounded-lg md:m-4 lg:m-6">
          <Image
            src={pictureUrl}
            loader={storyBlokLoader}
            layout="fill"
            objectFit="cover"
            alt="contact person profile"
            data-testid="contact-image"
            className="z-10 rounded"
          />
        </div>
        <div
          className={classNames(
            'absolute bottom-0 h-[20%] w-full  md:right-0 md:h-full md:w-[20%] lg:right-0 lg:h-full lg:w-[30%]',
            primaryBgColor && `bg-${primaryBgColor}`
          )}
        />
      </div>
      <div
        className={classNames(
          'flex-1  rounded-b-lg px-4 md:rounded-r-lg md:rounded-l-none lg:rounded-r-lg lg:rounded-l-none',
          primaryBgColor && `bg-${primaryBgColor}`
        )}
      >
        <div
          className={classNames(
            'text-2xl md:mt-5 lg:mt-12 lg:text-2xl',
            primaryTextColor && `text-${primaryTextColor}`
          )}
          data-testid="contact-heading"
        >
          {firstname} {lastname}
        </div>
        <div className="mt-2">
          <h2 className={`text-${primaryTextColor}`}>Teilkaufberatung</h2>
          <div className="mt-5 flex lg:mt-10">
            <PhoneIcon className={classNames('mr-2 mt-0.5 h-5 w-5', svgPrimaryColor && `text-${svgPrimaryColor}`)} />
            <a
              className={classNames('cursor-pointer', primaryTextColor && `text-${primaryTextColor}`)}
              href={`tel:${phone}`}
              data-testid="contact-phone"
            >
              {phone}
            </a>
          </div>
          <div className="mt-4 flex cursor-pointer" onClick={() => openEMail(eMail)}>
            <MailIcon className={classNames('w-50 mr-2 mt-1 h-5', svgPrimaryColor && `text-${svgPrimaryColor}`)} />
            <a href={eMail} data-testid="contact-email" className={primaryTextColor && `text-${primaryTextColor}`}>
              {eMail}
            </a>
          </div>
          <Button className="my-5 w-full md:mt-6 md:w-auto lg:mt-16" onClick={() => openEMail(eMail)}>
            Ansprechpartner kontaktieren
          </Button>
        </div>
      </div>
    </div>
  );
}
