/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieBasisdatenImmobilientyp =
  | 'Einfamilienhaus'
  | 'Einfamilienhaus mit Einliegerwohnung'
  | 'Zweifamilienhaus'
  | 'Eigentumswohnung'
  | 'Mehrfamilienhaus'
  | 'unbebautes Grundstück';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieBasisdatenImmobilientyp = {
  Einfamilienhaus: 'Einfamilienhaus' as ImmobilieBasisdatenImmobilientyp,
  Einfamilienhaus_mit_Einliegerwohnung: 'Einfamilienhaus mit Einliegerwohnung' as ImmobilieBasisdatenImmobilientyp,
  Zweifamilienhaus: 'Zweifamilienhaus' as ImmobilieBasisdatenImmobilientyp,
  Eigentumswohnung: 'Eigentumswohnung' as ImmobilieBasisdatenImmobilientyp,
  Mehrfamilienhaus: 'Mehrfamilienhaus' as ImmobilieBasisdatenImmobilientyp,
  unbebautes_Grundstck: 'unbebautes Grundstück' as ImmobilieBasisdatenImmobilientyp,
};
