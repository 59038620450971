import { IdentificationIcon, PhoneIcon } from '@heroicons/react/outline';
import { NavigationRoutesProps } from '@dtk/ui-components';

export const profileRoutes: NavigationRoutesProps[] = [
  {
    href: '/personal',
    name: 'Mein Profil',
    guideTourId: 'nav-personal',
    icon: <IdentificationIcon className="h-6 w-6" />,
  },
  {
    href: '/kontakt',
    name: 'Mein Teilkaufberater',
    guideTourId: 'nav-contact',
    icon: <PhoneIcon className="h-6 w-6" />,
  },
];
