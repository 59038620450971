import { Popover } from '@headlessui/react';
import { InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import { PreferedPosition } from '.';


export const PopOver = ({ content, colorVariant, header = '', isOpen, setOpen, preferedPosition = 'auto', events }: PopOver) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: preferedPosition,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 10],
        },
      },
    ],
  });

  const colors = getThemeColors(colorVariant);

  return (
    <Popover>
      <Popover.Button as="div" style={{ pointerEvents: 'all' }} ref={setReferenceElement} className={'h-5 w-5'}>
        <button {...events}>
          <InformationCircleIcon className='w-6 h-6' />
        </button>
      </Popover.Button>

      {isOpen &&
        (<Popover.Panel
          className={`${colors.background} ${colors.text} p-4 pb-5 text-base shadow-lg rounded max-w-sm z-50`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          static
        >
          <div className={`pb-2 font-semibold flex flex-row items-center ${colors.text} ${colors.background}`}>
            <InformationCircleIcon className="h-7 w-7 mr-2" aria-hidden="true" />
            <div>{header}</div>
            <button className='justify-self-end ml-auto' onClick={() => setOpen(false)}>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          {content}
        </Popover.Panel>
        )}
    </Popover>
  );
};

const getThemeColors = (colorVariant: string) => {
  let background = '';
  let text = '';
  if (colorVariant === 'dark') {
    background = classNames('bg-coolGray-800');
    text = classNames('text-white');
  }
  if (colorVariant === 'light') {
    background = classNames('bg-white');
    text = classNames('text-navy');
  }
  if (colorVariant === 'gray') {
    background = classNames('bg-coolGray-100');
    text = classNames('text-coolGray-500');
  }

  return { background, text };
};

export interface PopOver {
  content: ReactNode;
  header?: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  colorVariant: PopOverColor;
  preferedPosition?: PreferedPosition;
  events: {
    ['onMouseEnter']?: () => void,
    ['onMouseLeave']?: () => void,
    ['onClick']?: () => void
  };
}
export type PopOverColor = 'light' | 'dark' | 'gray'
