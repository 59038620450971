import classNames from 'classnames';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { Steps, StepStatus } from './';

export const Step = ({
  step,
  stepIdx,
  focusedStep,
  handleCurrent,
  handleStep,
  stepTextColor,
  stepInfoIconColor,
  stepNumberColor,
}: StepProps) => {
  const handleClick = (status: StepStatus) => {
    if (status === StepStatus.current) {
      if (handleCurrent) {
        handleCurrent();
      }

      return;
    }

    if (handleStep) {
      handleStep(step.key);
    }
  };

  if (focusedStep !== step.id) {
    return null;
  }

  return (
    <div className="text-navy flex h-full flex-col items-center">
      <div className="relative text-center" onClick={() => handleClick(step?.status)}>
        {step.status === StepStatus.current && (
          <div className="border-gold-sand absolute -top-[6px] -left-[6px] -bottom-1 z-10 flex h-[54px] w-[54px] justify-center rounded-full border-2 bg-white hover:bg-indigo-900" />
        )}

        <button
          data-testid="button-step"
          className={classNames(
            'relative z-20 flex h-[42px] w-[42px] items-center justify-center rounded-full text-[24px]',
            step.status === StepStatus.upcoming ? 'bg-[#ccc]' : `bg-${stepNumberColor}`
          )}
        >
          {step.status === StepStatus.complete ? (
            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
          ) : (
            <span className="text-white">{stepIdx + 1}</span>
          )}

          <span className="sr-only">{step.name}</span>
        </button>
      </div>

      <div
        className={classNames(
          'flex h-full w-full flex-col items-center justify-between pt-2 text-center ',
          step.status === StepStatus.current ? 'font-semibold' : ''
        )}
      >
        <div className={classNames(stepTextColor && `text-${stepTextColor}`, 'text-lg leading-[22.63px]')}>
          {step.description}
        </div>

        <div className="inline-block align-middle">
          {handleCurrent && handleStep && (
            <button
              onClick={() => handleClick(step.status)}
              className="flex items-center pl-1 text-sm sm:text-base"
              data-testid={`info-button`}
            >
              <InformationCircleIcon className={classNames('w-6', stepInfoIconColor && `text-${stepInfoIconColor}`)} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

interface StepProps {
  step: Steps;
  stepIdx: number;
  focusedStep: number;
  handleCurrent?: () => void;
  handleStep?: (status: string) => void;
  stepTextColor?: string;
  stepInfoIconColor?: string;
  stepNumberColor?: string;
}
