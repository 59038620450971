import { Info, TooltipColor } from '..';

import { Visible } from '../../01_elements';
import classNames from 'classnames';

export interface InfoCardProps {
  title: string;
  titleColor?: string;
  tooltipText: string;
  tooltipColorVariant?: TooltipColor;
  tooltipIconColor?: string;
  containerStyle?: string;
  children?: React.ReactNode;
  backgroundColor?: string;
}

export function InfoCard({
  title,
  titleColor,
  tooltipText,
  tooltipColorVariant,
  tooltipIconColor,
  containerStyle,
  backgroundColor,
  children,
}: InfoCardProps) {
  return (
    <div className={classNames('h-full', containerStyle, backgroundColor ? `bg-${backgroundColor}` : 'bg-white')}>
      <div className="xs:px-4 xs:pt-4 flex h-[100%] flex-col px-4 pt-4 pb-4 md:px-4 md:pt-4 lg:px-6 lg:pt-6 lg:pb-12">
        <div className="text-navy flex w-full justify-between">
          <div className={classNames('text-lg font-semibold', titleColor && `text-${titleColor}`)}>{title}</div>

          <Visible when={Boolean(tooltipText)}>
            <div className={classNames('flex items-center ', tooltipIconColor && `text-${tooltipIconColor}`)}>
              <Info content={tooltipText} variant="tooltip" colorVariant={tooltipColorVariant} />
            </div>
          </Visible>
        </div>
        <div className="border-coolGray-200 my-3 border-b-2"></div>
        <div className="flex h-full flex-col">{children}</div>
      </div>
    </div>
  );
}
