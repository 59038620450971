export const ResolveRelations = [
  'presse_post.posts',
  'presse_post.categories',
  'presse_post.author',
  'presse_post.postsCarousel',
  'blog_post.posts',
  'blog_post.postsCarousel',
  'blog_post.author',
  'blog_post.categories',
  'PostsCarousel.posts',
  'PostsCarousel.categories',
  'PostsCarousel.author',
  'PostsCarousel.presse_post',
  'postColumns.posts',
  'postColumns.categories',
  'postsListing.posts',
  'postsListing.categories',
  'LexikonListing.posts',
  'lexikon_new.LexikonListing',
  'blog_posts_listing.posts',
  'blog_posts_listing.categories',
  'global_reference.reference',
  'posts.author',
  'posts.categories',
  'NavigationLink.featuredBlogPosts',
  'categoryOverviewPage.posts',
  'categoryOverviewPage.categories',
  'categoryOverviewPage.blog_posts_listing',
  'blog_posts_listing.categoryOverviewPage',
  'TasksCard.tasks',
  'InfoCardWithValue.theme',
  'DashboardDTKIndex.theme',
  'InfoCardWithValueIcon.theme',
  'ProcessStatusCard.theme',
  'PropertyShareCard.theme',
  'DashboardContactCard.theme',
  'TasksCard.theme',
  'DashboardDTKIndex.theme',
  'MarketPriceTrendCard.theme',
];
