import { Placeholder } from './Placeholder';

export const StoryblokComponent = ({ blok, components, data }) => {
  if (typeof components[blok?.component] !== 'undefined') {
    const Component = components[blok.component];

    return <Component blok={blok} data={data} />;
  }

  return <Placeholder componentName={blok?.component} />;
};
