import { Headline } from '../../01_elements';

export function ContactInfoContent() {
  return (
    <>
      <Headline headingType="h2" headline="Haben Sie Interesse an unserem Vollmachtservice?" isBold />
      <div className="text-navy mx-auto mt-5 px-4 sm:px-6 md:max-w-xl lg:max-w-2xl lg:px-8 xl:max-w-[50rem]">
        <p>Bitte kontaktieren Sie unsere Kollegen aus dem Kundenservice.</p>
        <br />
        <p>
          Unser Team hilft Ihnen gerne weiter und beantwortet Ihnen alle Fragen rund um dieses Thema. Sie erreichen uns
          Montag bis Freitag von 08:00 - 18:00 Uhr unter der Telefonnummer:
        </p>
        <br />
        <p className="font-headline text-gold-600 text-4xl font-thin">
          <a href="tel:+022171829100">0221 718 29 - 100</a>
        </p>
      </div>
    </>
  );
}
