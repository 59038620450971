/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type StageStatus =
  | 'Initial'
  | 'Gutachten'
  | 'Bewertung'
  | 'Kaufvertrag'
  | 'unverbindliches Angebot'
  | 'verbindliches Angebot'
  | 'Angebot angenommen'
  | 'Angebot nicht angenommen';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StageStatus = {
  Initial: 'Initial' as StageStatus,
  Gutachten: 'Gutachten' as StageStatus,
  Bewertung: 'Bewertung' as StageStatus,
  Kaufvertrag: 'Kaufvertrag' as StageStatus,
  unverbindliches_Angebot: 'unverbindliches Angebot' as StageStatus,
  verbindliches_Angebot: 'verbindliches Angebot' as StageStatus,
  Angebot_angenommen: 'Angebot angenommen' as StageStatus,
  Angebot_nicht_angenommen: 'Angebot nicht angenommen' as StageStatus,
};
