import classNames from 'classnames';

export interface CardHeaderProps {
  headline: string;
  description?: string;
  badge?: string;
}

export const CardHeader = ({ headline, description, badge }: CardHeaderProps) => {
  return (
    <div className="py-4">
      {badge && (
        <span className="bg-navy-600 mb-2 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium text-white">
          {badge}
        </span>
      )}
      <h1 className={classNames(description && 'mb-2', 'text-gold-gold text-2xl ')}>{headline}</h1>
      <h2 className="text-l whitespace-pre-wrap">{description}</h2>
    </div>
  );
};
