export const Content = ({ text }: ContentProps) => {
  if (typeof text === 'string') {
    return <p className="mt-2 text-base md:mt-4 md:text-lg 2xl:mt-5">{text}</p>;
  }
  return text as React.ReactElement;
};

export type ContentProps = {
  text: string | React.ReactNode;
};
