import { Button, ButtonVariantsRedesign } from '../../01_elements';
import { AuthenticationHeaderText } from './AuthenticationHeaderText';
import { ReactNode } from 'react';
import { FooterVariant, AuthenticationCardFooter } from './AuthenticationFooter';

export type SecondaryButton = { text: string; loginType: AuthenticationType; variant?: ButtonVariantsRedesign };

export interface AuthenticationCardProps {
  overline: string;
  headline: string;
  subtitle: string;
  footerVariant: FooterVariant;
  children: ReactNode;
  secondaryButton?: SecondaryButton;
  setLoginType: (loginType: AuthenticationType) => void;
}

export function AuthenticationCard({
  overline,
  headline,
  subtitle,
  footerVariant,
  children,
  secondaryButton,
  setLoginType,
}: AuthenticationCardProps) {
  return (
    <div className="bg-coolGray-100 py-14">
      <div className="text-center">
        <AuthenticationHeaderText overline={overline} headline={headline} subtitle={subtitle} />
      </div>
      <div className="mx-4 mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-coolGray-50 space-y-4 py-8 px-4 shadow-xl sm:rounded-lg sm:px-10">
          {children}
          {footerVariant === 'magic-link' && (
            <AuthenticationCardFooter setLoginType={setLoginType} variant="magic-link" />
          )}
          {secondaryButton && (
            <>
              {footerVariant !== 'none' && (
                <div>
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="bg-coolGray-50 text-coolGray-500 px-2">oder</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="w-full">
                <Button
                  variant={secondaryButton.variant || 'white'}
                  fullWidth
                  onClick={() => setLoginType(secondaryButton.loginType)}
                >
                  {secondaryButton.text}
                </Button>
              </div>
            </>
          )}

          {footerVariant !== 'magic-link' && (
            <AuthenticationCardFooter setLoginType={setLoginType} variant={footerVariant} />
          )}
        </div>
      </div>
    </div>
  );
}
export type AuthenticationType = 'magiclink' | 'credentials' | 'new-password' | 'registration';
