import { NotificationProvider, Spinner } from '@dtk/ui-components';
import axios from 'axios';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAuthState } from '../store/useAuthState';
import { useAppConfig } from '@dtk/config';
import AuthenticationProvider from './AuthenticationProvider/AuthenticationProvider';

const queryClient = new QueryClient();

interface ProviderProps {
  children?: JSX.Element;
}

export function Provider({ children }: ProviderProps) {
  const authState = useAuthState();
  const configStore = useAppConfig();

  useEffect(() => {
    configStore.getLatest();
  }, [configStore]);

  useEffect(() => {
    if (configStore.ready) {
      authState.setConfig({
        cognitoClientId: configStore.config.USER_POOL_CLIENT_ID,
        cognitoUserPool: configStore.config.USER_POOL_ID,
        region: 'eu-central-1',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configStore]);

  if (!authState.config) {
    return <Spinner></Spinner>;
  }

  axios.defaults.baseURL = configStore.config.API_BASE;

  useAuthState.subscribe((state) => {
    if (state.user?.accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.user.accessToken}`;
    }
  });
  return (
    <>
      <NotificationProvider />
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider children={children} config={authState.config} />
      </QueryClientProvider>
    </>
  );
}
