import { useEffect } from 'react';
import { StreamState } from '../hooks/useEventSource';
import { useWebsocket } from '../hooks/useWebsocket';
/* eslint-disable-next-line */
export interface ChallengeResponseAwaiterProps {
  url: URL;
  onChallengeAnswered: { (answer: ChallengeAnswer): void };
  onError?: { (): void };
}

export const ChallengeResponseAwaiter = (props: ChallengeResponseAwaiterProps) => {
  const eventState = useWebsocket(props.url, {
    challengeResponse: (response: string) => props.onChallengeAnswered(response),
  });

  const errorCallback = props.onError;
  useEffect(() => {
    if (eventState.state === StreamState.ERROR && errorCallback) {
      errorCallback();
    }
  }, [eventState, errorCallback]);
  return null;
};

export type ChallengeAnswer = string;

export default ChallengeResponseAwaiter;
