import { smalllogoSrc } from './utils';

export const SmallDtkLogo = ({ ...props }) => (
  <img
    {...props}
    title="Deutsche Teilkauf Logo"
    alt="Deutsche Teilkauf Logo Small"
    src={smalllogoSrc}
    aria-label="Deutsche Teilkauf Logo"
    width="32"
    height="32"
    loading={props.loading}
    {...{ fetchpriority: props.loading === 'eager' ? 'high' : 'low' }}
  />
);
