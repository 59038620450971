import { FieldHookConfig } from 'formik';
import { ReactNode } from 'react';
import { DtkSlider, DtkSliderProps, InputFieldWithAddOn, Prefix, Suffix } from '../01_elements';

export interface InputAndSliderProps {
  header: string;
  info?: ReactNode;
  value: number;
  inputPrefix?: Prefix;
  inputSuffix?: Suffix;
  min: number;
  max: number;
  step?: number;
  sliderValue: number;
  sliderOnChange: (value: number | number[]) => void;
  sliderProps?: DtkSliderProps;
}

export const InputAndSlider = (
  props: React.HTMLProps<HTMLInputElement> & FieldHookConfig<number> & InputAndSliderProps
) => {
  const {
    header,
    info = null,
    value,
    inputSuffix,
    sliderProps,
    min,
    max,
    step = 1,
    sliderValue,
    sliderOnChange,
    ...restProps
  } = props;

  return (
    <>
      <div className="mb-2 flex flex-row justify-between">
        <label className="text-navy text-lg font-semibold">{header}</label>
        {info}
      </div>
      <InputFieldWithAddOn
        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
          //Hackfleisch
          sliderOnChange(Number(e.currentTarget.value));
        }}
        suffix={inputSuffix}
        min={min}
        step={step}
        max={max}
        {...restProps}
      />
      <DtkSlider
        className="mt-5"
        defaultValue={sliderValue}
        step={step}
        value={sliderValue}
        min={min}
        max={max}
        onChange={sliderOnChange}
        {...sliderProps}
      />
    </>
  );
};
