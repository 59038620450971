import { useEffect, useState } from 'react';

import { ResolveRelations } from '../resolve-relations';
import { Story } from '../types/types';
import { getStoryblokApi } from '@storyblok/react';

const isDev = process.env['NODE_ENV'] === 'development';

export interface UseStoryProps {
  content: string;
  slug: string;
}

interface StoryResponse<StorybookContent> {
  story: Story<StorybookContent>;
  loading: boolean;
  error: string;
}

export function useStory<StorybookContent>({ content, slug }: UseStoryProps): StoryResponse<StorybookContent> {
  const storyblokApi = getStoryblokApi();
  const [story, setStory] = useState<Story<StorybookContent>>({} as Story<StorybookContent>);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const sbParams = {
    version: isDev ? 'draft' : 'public', // or "published"
    resolve_relations: ResolveRelations,
  };

  const fetchStory = async () => {
    try {
      const { data } = await storyblokApi.get(`cdn/stories/${content}/${slug}`, sbParams);
      setStory(data.story);
      setLoading(false);
    } catch (err) {
      setError((err as Error).message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchStory();
  }, [slug]);

  return { story, loading, error };
}
