// Update if new fields are added
export type Theme = {
  content: {
    global: [
      {
        _uid: string;
        component: string;
        primaryTextColor?: string;
        secondaryTextColor?: string;
        tertiaryTextColor?: string;
        primaryTextColorComplementary?: string;
        primaryBgColor?: string;
        secondaryBgColor?: string;
        infoIconColor?: string;
        infoIconTooltipColorVariant?: string;
      }
    ];
  };
};

export const getStoryblokTheme = (theme: Theme) => {
  const themeObject = theme?.content?.global[0];
  if (themeObject) {
    const {
      primaryBgColor,
      primaryTextColor,
      secondaryTextColor,
      primaryTextColorComplementary,
      secondaryBgColor,
      tertiaryTextColor,
      infoIconColor,
      infoIconTooltipColorVariant,
    } = themeObject;
    return {
      primaryBgColor,
      primaryTextColor,
      secondaryTextColor,
      primaryTextColorComplementary,
      secondaryBgColor,
      tertiaryTextColor,
      infoIconColor,
      infoIconTooltipColorVariant,
    };
  }

  return {};
};
