import { useGetStage, useStageContext } from '@dtk/query';
import { mainRoutes } from '../routes/mainRoutes';
import { useRouter } from 'next/router';
import { Spinner, Navigation } from '@dtk/ui-components';

export default function NavigationBar() {
  const stageContext = useStageContext();
  const { data: stage, isLoading } = useGetStage();
  const { pathname } = useRouter();

  if (isLoading) {
    return <Spinner />;
  }

  if (!stage || !stage.data) {
    return <Navigation stage={null} stageContext={null} routes={mainRoutes} pathname={pathname} />;
  }

  const { status } = stage.data;

  return <Navigation stage={status} stageContext={stageContext} routes={mainRoutes} pathname={pathname} />;
}
