/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieBauweiseDachausbau = 'Voll ausgebaut' | 'teilweise ausgebaut' | 'nicht ausgebaut' | 'Flachdach';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieBauweiseDachausbau = {
  Voll_ausgebaut: 'Voll ausgebaut' as ImmobilieBauweiseDachausbau,
  teilweise_ausgebaut: 'teilweise ausgebaut' as ImmobilieBauweiseDachausbau,
  nicht_ausgebaut: 'nicht ausgebaut' as ImmobilieBauweiseDachausbau,
  Flachdach: 'Flachdach' as ImmobilieBauweiseDachausbau,
};
