import NavigationBar from './NavigationBar';

interface LayoutProps {
  children?: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="font-body flex min-h-screen flex-col">
      <NavigationBar />
      <main className="flex-grow">{children}</main>
    </div>
  );
}
