import Image from 'next/image';
import classNames from 'classnames';
import { GreetingsHeroSectionProps } from './types';
import { storyBlokLoader } from '../../utils/storyblok-loader';

export function GreetingsHeroSection({
  externalData,
  customerSalutation,
  customerLastname,
  gradientbackgroundClassNames,
  textColor,
}: GreetingsHeroSectionProps) {
  const { image, title: greetingsFormula, subtitle } = externalData;
  const greetingsText =
    greetingsFormula && customerSalutation && customerLastname
      ? `${greetingsFormula}, ${customerSalutation} ${customerLastname}.`
      : `${greetingsFormula}`;

  return (
    <section className="relative">
      <div
        className={classNames(
          'absolute top-0 bottom-0 right-0 overflow-hidden',
          'xs:lg:min-h-[180px] xs:left-0 xs:w-full md:left-auto md:min-h-[152px] md:w-1/2 lg:min-h-[180px] xl:w-1/2'
        )}
      >
        <Image
          src={image?.filename}
          className="object-cover object-center"
          layout="fill"
          loader={storyBlokLoader}
          alt={image?.alt}
          priority
        />
      </div>

      <div
        className={classNames(
          !gradientbackgroundClassNames && 'bg-navy-800',
          'xs:opacity-75 xs:min-h-[180px] absolute right-0 top-0 bottom-0 w-full skew-x-[0deg]',
          'overflow-hidden md:right-[36%] md:min-h-[152px] md:skew-x-[-20deg] md:opacity-100 lg:min-h-[180px] xl:right-[35%]'
        )}
        style={{ background: gradientbackgroundClassNames }}
      />

      <div className="xs:min-h-[180px] relative mx-auto md:min-h-[152px] lg:min-h-[180px] xl:max-w-7xl ">
        <div className="h-full w-full px-[1rem] pt-[2rem]">
          <div
            className={classNames(
              'w-full text-left lg:h-full',
              'xl:1/2  md:w-5/6 md:max-w-[50%] lg:mr-[50%] lg:max-w-[63%] xl:mr-[50%]',
              'xs:items-center flex flex-col md:items-start'
            )}
          >
            <div className="flex h-full flex-col md:justify-between">
              {greetingsFormula && (
                <h1
                  className={classNames(
                    textColor ? `text-${textColor}` : 'text-white',
                    'font-headline xs:leading-[36px] text-3xl font-bold',
                    'xs:text-[23px] sm:text-[25px] md:text-[28px] lg:text-[40px] lg:leading-[57.6px] xl:text-[44px]'
                  )}
                >
                  {greetingsText}
                </h1>
              )}
              {subtitle && (
                <p
                  className={classNames(
                    textColor ? `text-${textColor}` : 'text-white',
                    'xs:pb-[2rem] mt-3 pb-4 text-base md:mt-4 md:text-lg xl:mt-8'
                  )}
                >
                  {subtitle}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
