import { MailIcon } from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, InputField, validationMessages } from '@dtk/ui-components';

const MagicLinkSchema = Yup.object().shape({
  email: Yup.string()
    .email(validationMessages.error.email.text)
    .required(validationMessages.error.defaultInputField.text),
});

export interface EmailFields {
  email: string;
}

export const EmailForm = ({ onSubmit }: EmailFormProps) => {
  return (
    <Formik<EmailFields>
      initialValues={{ email: '' }}
      validationSchema={MagicLinkSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      <Form data-testid="set-email-form" className="space-y-6">
        <InputField
          data-testid="email"
          placeholder="max@mustermann.de"
          name="email"
          labelBold={true}
          type="text"
          label="Ihre E-Mail-Adresse"
          icon={MailIcon}
          infoMessage={validationMessages.info.email}
        />

        <Button data-testid="submit" type="submit" variant="navy" fullWidth>
          Weiter
        </Button>
      </Form>
    </Formik>
  );
};

export interface EmailFormProps {
  onSubmit: (values: EmailFields) => void;
}
