import { useEffect, useState } from 'react';

export const useCountdown = ({ countdown, handleCallback }: UseCountdownProps) => {
  const [time, setTime] = useState<UseCountdownTimeProps>({
    minutes: countdown.minutes,
    seconds: countdown.seconds,
  });

  const tick = () => {
    if (time.minutes === 0 && time.seconds === 0) {
      handleCallback();
    } else if (time.seconds === 0) {
      setTime({ minutes: time.minutes - 1, seconds: 59 });
    } else {
      setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return time;
};

export interface UseCountdownProps {
  countdown: UseCountdownTimeProps;
  handleCallback: { (): void };
}

export interface UseCountdownTimeProps {
  minutes: number;
  seconds: number;
}
