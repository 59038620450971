import { Button } from '../../01_elements';

export function TaskButton({ href, onClick, text }: TaskButtonProps) {
  return href ? (
    <Button
      className="border-coolGray-300 flow-root w-full py-[12px] text-center drop-shadow md:w-[221px] md:py-[8px]"
      variant="white"
      size="sm"
      href={href}
      onClick={onClick}
    >
      <span className="text-[16px] font-semibold">{text}</span>
    </Button>
  ) : (
    <Button
      className="border-coolGray-300 flow-root w-full py-[12px] text-center drop-shadow md:w-[221px] md:py-[8px]"
      variant="white"
      size="sm"
      onClick={onClick}
    >
      <span className="text-[16px] font-semibold">{text}</span>
    </Button>
  );
}

export interface TaskButtonProps {
  href?: string;
  text: string;
  onClick: () => void;
}
