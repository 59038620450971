/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieAusstattungBadtyp = 'innenliegend' | 'mit Fenster' | 'mehr als ein Bad';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieAusstattungBadtyp = {
  innenliegend: 'innenliegend' as ImmobilieAusstattungBadtyp,
  mit_Fenster: 'mit Fenster' as ImmobilieAusstattungBadtyp,
  mehr_als_ein_Bad: 'mehr als ein Bad' as ImmobilieAusstattungBadtyp,
};
