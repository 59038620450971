import { DtkConfig } from '../AuthenticationDialog';
import { AuthenticationType } from '@dtk/ui-components';
import { MagicLink } from '@dtk/magic-link';
import { useAuthState } from '../../store';
import { useRouter } from 'next/router';

export interface MagicLinkLoginFormProps {
  config: DtkConfig;
  setLoginType: (loginType: AuthenticationType) => void;
  email?: string;
}

export const MagicLinkDialog = (props: MagicLinkLoginFormProps) => {
  const { config, setLoginType } = props;
  const { query } = useRouter();
  const authState = useAuthState();
  const mail = query.mail ? (query.mail as string) : (props?.email as string);
  const token = query.token ? (query.token as string) : '';

  return (
    <MagicLink
      onSuccess={() => authState.changed()}
      mail={mail}
      oneTimeToken={token}
      clientId={config.cognitoClientId}
      userPoolId={config.cognitoUserPool}
      setLoginType={setLoginType}
    />
  );
};
