import { AuthenticationCard } from '@dtk/ui-components';
import { useEffect, useState } from 'react';
import { NewPasswordForm } from '../NewPasswordForm';
import { NewPasswordDialogProps, NewPasswordDialogState, PasswordPhase, getCurrentState } from '../../newPasswordTypes';

export const NewPasswordDialog = ({ setLoginType, email }: NewPasswordDialogProps) => {
  const [passwordPhase, setPasswortPhase] = useState<PasswordPhase>(email ? 'SET_PASSWORD' : 'SET_EMAIL');

  const [state, setState] = useState<NewPasswordDialogState>(getCurrentState(passwordPhase));

  useEffect(() => {
    const emailSessionStorage = sessionStorage.getItem('dtk-temp-email');
    const email = emailSessionStorage ? emailSessionStorage : undefined;
    setState(getCurrentState(passwordPhase, email));
  }, [passwordPhase]);

  return (
    <AuthenticationCard
      overline={state.overline}
      headline={state.headline}
      subtitle={state.subtitle}
      footerVariant="none"
      setLoginType={setLoginType}
      secondaryButton={state.secondaryButton}
    >
      <NewPasswordForm passwordPhase={passwordPhase} setPasswordPhase={setPasswortPhase} email={email} />
    </AuthenticationCard>
  );
};
