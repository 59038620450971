/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieEnergieeffizienzEnergieeffizienzhausstandard =
  | 'k.A.'
  | 'kfW-Effizienzhaus 40plus'
  | 'kfW-Effizienzhaus 40'
  | 'Passivhausstandard'
  | 'kfW-Effizienzhaus 55 Ref. Werte'
  | 'kfW-Effizienzhaus 55'
  | 'kfW-Effizienzhaus 70'
  | 'kfW-Effizienzhaus 85'
  | 'kfW-Effizienzhaus 100'
  | 'kfW-Effizienzhaus 115'
  | 'kfW-Effizienzhaus Denkmal';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieEnergieeffizienzEnergieeffizienzhausstandard = {
  kA: 'k.A.' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_40plus': 'kfW-Effizienzhaus 40plus' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_40': 'kfW-Effizienzhaus 40' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  Passivhausstandard: 'Passivhausstandard' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_55_Ref_Werte':
    'kfW-Effizienzhaus 55 Ref. Werte' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_55': 'kfW-Effizienzhaus 55' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_70': 'kfW-Effizienzhaus 70' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_85': 'kfW-Effizienzhaus 85' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_100': 'kfW-Effizienzhaus 100' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_115': 'kfW-Effizienzhaus 115' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  'kfW-Effizienzhaus_Denkmal': 'kfW-Effizienzhaus Denkmal' as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
};
