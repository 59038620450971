/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieObjektinformationenFenster =
  | 'einfach verglast'
  | 'zweifach verglast'
  | 'dreifach verglast'
  | 'Kastenfenster'
  | 'große feststehende Fensterflächen'
  | 'Spezialverglasung'
  | 'Sonstige';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieObjektinformationenFenster = {
  einfach_verglast: 'einfach verglast' as ImmobilieObjektinformationenFenster,
  zweifach_verglast: 'zweifach verglast' as ImmobilieObjektinformationenFenster,
  dreifach_verglast: 'dreifach verglast' as ImmobilieObjektinformationenFenster,
  Kastenfenster: 'Kastenfenster' as ImmobilieObjektinformationenFenster,
  groe_feststehende_Fensterflchen: 'große feststehende Fensterflächen' as ImmobilieObjektinformationenFenster,
  Spezialverglasung: 'Spezialverglasung' as ImmobilieObjektinformationenFenster,
  Sonstige: 'Sonstige' as ImmobilieObjektinformationenFenster,
};
