import { Customer, usePatchCustomer } from '@dtk/query';
import {
  AuthenticationType,
  Button,
  InputField,
  InputFieldPassword,
  OpenMailIcon,
  validationMessages,
} from '@dtk/ui-components';
import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/outline';

import { Auth } from 'aws-amplify';
import { Form, Formik, FormikProps } from 'formik';
import { KeyboardEvent, useState } from 'react';
import { object, string } from 'yup';
import { EmailForm } from './Login/form/EmailForm';
import { PasswordPhase } from '../newPasswordTypes';

const newPasswordSchema = object().shape({
  password: string()
    .required(validationMessages.error.defaultInputField.text)
    .min(validationMessages.newPassword[0].checkValue as number, validationMessages.newPassword[0].criterion)
    .matches(validationMessages.newPassword[1].checkValue as RegExp, validationMessages.newPassword[1].criterion)
    .matches(validationMessages.newPassword[2].checkValue as RegExp, validationMessages.newPassword[2].criterion)
    .matches(validationMessages.newPassword[3].checkValue as RegExp, validationMessages.newPassword[3].criterion),
});

export interface NewPasswordFields {
  password: string;
  'new-password': string;
  code: string;
}

export const NewPasswordForm = ({ email, setPasswordPhase, passwordPhase, customerData }: NewPasswordFormProps) => {
  const { mutate } = usePatchCustomer();
  const [currentEmail, setCurrentEmail] = useState(email as string);

  const handleBeginForgotPassword = async () => {
    Auth.forgotPassword(currentEmail.toLowerCase())
      .then(() => setPasswordPhase('ENTER_CODE'))
      .catch(() => {
        setPasswordPhase('ERROR');
      });
  };

  const handleFinishForgotPassword = async (values: NewPasswordFields) => {
    Auth.forgotPasswordSubmit(currentEmail.toLowerCase(), values.code, values.password)
      .then(() => {
        if (customerData && !customerData.hasPortalPassword) {
          mutate({
            data: {
              salutation: customerData?.salutation,
              firstname: '',
              lastname: customerData?.lastname,
              eMail: customerData?.eMail,
              phone: '',
              street: '',
              postCode: '',
              city: '',
              hasPortalPassword: true,
            },
          });
          sessionStorage.setItem('DTK-passwordSet-newlyRegisteredCustomer', customerData?.eMail);
        }

        const authenticationType: AuthenticationType = 'credentials';
        localStorage.setItem('DTK-new-preferredAuthenticationTypeType', authenticationType);
        setPasswordPhase('SUCCESS');
      })
      .catch(() => setPasswordPhase('ERROR'));
  };

  if (passwordPhase === 'SET_EMAIL') {
    return (
      <EmailForm
        onSubmit={(values) => {
          setCurrentEmail(values.email);
          setPasswordPhase('SET_PASSWORD');
          sessionStorage.setItem('dtk-temp-email', values.email);
        }}
      />
    );
  }

  return (
    <Formik<NewPasswordFields>
      initialValues={{ password: '', 'new-password': '', code: '' }}
      validationSchema={newPasswordSchema}
      validateOnChange={false}
      onSubmit={(value) => handleFinishForgotPassword(value)}
    >
      {(props: FormikProps<NewPasswordFields>) => (
        <Form>
          <div
            data-testid="set-password-form"
            className="space-y-6"
            hidden={passwordPhase !== 'SET_PASSWORD' && passwordPhase !== 'SET_PASSWORD_REGISTRATION'}
          >
            <InputFieldPassword
              data-testid="password"
              disableErrorMessages={true}
              name="password"
              label="Ihr Passwort"
              allCriteriaItems={validationMessages.newPassword}
              autoComplete="new-password"
              labelBold={true}
              hasSuccessMarker
              tooltip={{ hasTooltip: true, tooltipContent: validationMessages.info.newPassword.text }}
              onKeyDown={(event: KeyboardEvent) => event.key === 'Enter' && event.preventDefault()}
            />
            <InputFieldPassword
              data-testid="new-password"
              name="new-password"
              label="Ihr Passwort wiederholen"
              autoComplete="new-password"
              labelBold={true}
              hasSuccessMarker
              validate={(value) => validateConfirmPassword(props.values.password, value)}
              onKeyDown={(event: KeyboardEvent) => event.key === 'Enter' && event.preventDefault()}
            />
            <Button
              data-testid="submit-password"
              type="button"
              variant="navy"
              fullWidth
              onClick={props.dirty && props.isValid ? handleBeginForgotPassword : undefined}
            >
              Passwort vergeben
            </Button>
          </div>

          <div
            data-testid="enter-code-form"
            className="space-y-6"
            hidden={passwordPhase !== 'ENTER_CODE' && passwordPhase !== 'ENTER_CODE_REGISTRATION'}
          >
            <div className="flex justify-center">
              <OpenMailIcon />
            </div>
            <InputField
              name="code"
              type="text"
              label="Verifizierungscode"
              icon={ShieldCheckIcon}
              data-testid="verify-code"
            />
            {passwordPhase === 'ENTER_CODE' && (
              <div className="mt-4 text-center text-xs">
                <p>
                  Sie haben keine E-Mail erhalten?{' '}
                  <span className="text-cyan hover:text-cyan-900">
                    <button type="button" onClick={() => handleBeginForgotPassword()}>
                      Code erneut senden.
                    </button>
                  </span>
                </p>
              </div>
            )}
            <div className="w-full">
              <Button className="flex w-full justify-center" type="submit" variant="navy" data-testid="submit-code">
                Code bestätigen
              </Button>
            </div>
          </div>

          <div data-testid="success-phase" hidden={passwordPhase !== 'SUCCESS'} className="text-center">
            <div className="flex justify-center text-green-500">
              <ShieldCheckIcon className="h-12 w-12" />
            </div>
            <div className="space-y-4 py-4">
              <p>Ihr Passwort wurde erfolgreich geändert, melden Sie sich damit jetzt an.</p>
            </div>
          </div>

          <div data-testid="error-phase" hidden={passwordPhase !== 'ERROR'} className="text-center">
            <div className="text-red flex justify-center">
              <ShieldExclamationIcon className="h-12 w-12" />
            </div>
            <div className="space-y-4 py-4">
              <p>
                Es ist ein Fehler aufgetreten, bitte wiederholen Sie den Vorgang oder versuchen Sie es zu einem späteren
                Zeitpunkt erneut (bei mehrmals fehlgeschlagenen Code-Anforderungen).
              </p>
            </div>
            <Button variant="navy" fullWidth onClick={() => setPasswordPhase('SET_EMAIL')}>
              Vorgang wiederholen
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface NewPasswordFormProps {
  email?: string;
  passwordPhase: PasswordPhase;
  setPasswordPhase: (phase: PasswordPhase) => void;
  customerData?: Customer;
}

function validateConfirmPassword(password: string, confirmPassword: string) {
  if (!confirmPassword) {
    return validationMessages.error.defaultInputField.text;
  }
  if (confirmPassword === '') {
    return validationMessages.error.passwordConfirmation;
  }

  let error = '';
  if (password && confirmPassword) {
    if (password !== confirmPassword) {
      error = validationMessages.error.passwordConfirmation;
    }
  }
  return error;
}
