/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieBauweiseKellerart = 'Vollunterkellert' | 'Teilweise unterkellert' | 'Nicht unterkellert';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieBauweiseKellerart = {
  Vollunterkellert: 'Vollunterkellert' as ImmobilieBauweiseKellerart,
  Teilweise_unterkellert: 'Teilweise unterkellert' as ImmobilieBauweiseKellerart,
  Nicht_unterkellert: 'Nicht unterkellert' as ImmobilieBauweiseKellerart,
};
