import { AmazonRegion, CognitoClientId, CognitoUserPoolId } from '@dtk/types';

export interface DtkConfig {
  cognitoUserPool: CognitoUserPoolId;
  cognitoClientId: CognitoClientId;
  region: AmazonRegion;
}

export enum AuthState {
  Pending,
  SignUp,
  SignedIn,
  SignedOut,
}

export interface CurrentUser {
  name?: string;
  accessToken?: string;
}
