/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieObjektinformationenDacheindeckung =
  | 'Dachpappe'
  | 'Dachpfannen/-ziegel'
  | 'Kunstschiefer'
  | 'Naturschiefer/Metall'
  | 'Sonstige';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieObjektinformationenDacheindeckung = {
  Dachpappe: 'Dachpappe' as ImmobilieObjektinformationenDacheindeckung,
  'Dachpfannen-ziegel': 'Dachpfannen/-ziegel' as ImmobilieObjektinformationenDacheindeckung,
  Kunstschiefer: 'Kunstschiefer' as ImmobilieObjektinformationenDacheindeckung,
  NaturschieferMetall: 'Naturschiefer/Metall' as ImmobilieObjektinformationenDacheindeckung,
  Sonstige: 'Sonstige' as ImmobilieObjektinformationenDacheindeckung,
};
