import { CtaButton, CtaButtonProps } from './CtaButton';

import { Content } from './Content';
import Image from 'next/image';
import React from 'react';
import classNames from 'classnames';
import { storyBlokLoader } from '../../utils/storyblok-loader';

export interface DiagonalHeroSectionProps {
  status: string;
  content: string | React.ReactNode;
  title: string;
  ctaButton?: CtaButtonProps;
  imagePath: string;
}

export function DiagonalHeroSection({ status, content, title, ctaButton, imagePath }: DiagonalHeroSectionProps) {
  return (
    <section className="relative">
      <div className="absolute top-0 bottom-0 left-0 right-0 overflow-hidden">
        {imagePath && (
          <Image src={imagePath} className="h-full w-full object-cover" layout="fill" loader={storyBlokLoader} />
        )}
      </div>
      <div
        className={classNames(
          ' absolute right-0 top-0 bottom-0 w-full skew-x-[0deg] overflow-hidden backdrop-blur-xl  md:right-[44%]  md:skew-x-[-8.8deg] xl:right-[44%]'
        )}
        style={{
          backgroundColor: 'rgba(255,255,255,0.4)',
        }}
      />

      <div className=" grid-row-3 relative bottom-0 left-0 right-0 top-0 mx-auto grid h-full xl:max-w-7xl">
        <div className=" w-full  pb-10 pt-[7.8rem] sm:px-[1rem] md:w-2/3 md:pb-0 md:pt-[4.2rem]">
          <div
            className={classNames(
              'w-full px-6 py-8 text-left  md:py-8 lg:py-12 2xl:py-14 ',
              'lg:min-h-200 xl:1/2 md:w-5/6 lg:mr-[50%] lg:max-w-[41rem] xl:mr-[50%]'
            )}
          >
            <p className="text-gold mb-4 text-sm uppercase tracking-widest">{status}</p>
            <h1 className="text-navy font-headline text-4xl lg:text-5xl lg:leading-tight">{title}</h1>
            {content && <Content text={content} />}
            {ctaButton && <CtaButton {...ctaButton} />}
          </div>
        </div>
      </div>
    </section>
  );
}
