import { useRouter } from 'next/router';
import classNames from 'classnames';

import { SmallFooter } from '@dtk/ui-components';

export default function SmallFooterPortal() {
  const router = useRouter();
  const DASHBOARD_ROUTE = '/dashboard';

  return (
    <div className={classNames('bg-white pb-2', router.pathname !== DASHBOARD_ROUTE ? 'mt-7' : '')}>
      <SmallFooter
        datenschutzLink={'https://deutsche-teilkauf.de/datenschutz'}
        copyrightText={`Deutsche Teilkauf GmbH © ${new Date().getFullYear()}`}
        datenschutzText={'Datenschutz'}
        impressumText={'Impressum'}
        impressumLink={'https://deutsche-teilkauf.de/impressum/'}
      />
    </div>
  );
}
