import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

export interface DefaultModalProps {
  showDialog: boolean;
  handleModalClose?: (value: unknown) => void;
  children?: ReactNode;
}

export function DefaultModal({ showDialog, children, handleModalClose }: DefaultModalProps) {
  function closeModal(value: boolean) {
    return handleModalClose ? handleModalClose(value) : () => undefined;
  }

  return (
    <Transition appear show={showDialog} as={Fragment}>
      <Dialog as="div" className="fixed z-10" onClose={closeModal}>
        <div className="fixed inset-x-0 bottom-0 top-[60px] bg-black bg-opacity-50 lg:top-[120px]" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed bg-black" />
        </Transition.Child>

        <div className="fixed inset-x-0 top-[120px] bottom-0 flex w-screen items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-5 text-center align-middle shadow-xl transition-all">
              {handleModalClose && (
                <button className="float-right" onClick={handleModalClose}>
                  <XIcon width={20} height={20} />
                </button>
              )}
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
