/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Document Service
 * A service for displaying and modifying documents uploaded by the customer
 * OpenAPI spec version: 2021-11-17T08:07:55Z
 */

export type DocumentCategory =
  | 'grundbuchauszug'
  | 'grundriss'
  | 'flurplan'
  | 'wohnflaeche'
  | 'teilungserklaerung'
  | 'vollmacht'
  | 'teilkaufreport'
  | 'sonstige';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentCategory = {
  grundbuchauszug: 'grundbuchauszug' as DocumentCategory,
  grundriss: 'grundriss' as DocumentCategory,
  flurplan: 'flurplan' as DocumentCategory,
  wohnflaeche: 'wohnflaeche' as DocumentCategory,
  teilungserklaerung: 'teilungserklaerung' as DocumentCategory,
  vollmacht: 'vollmacht' as DocumentCategory,
  teilkaufreport: 'teilkaufreport' as DocumentCategory,
  sonstige: 'sonstige' as DocumentCategory,
};
