/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieAusstattungFussbodenbelag =
  | 'Kunststoff/PVC'
  | 'Fliesen'
  | 'Parkett|Naturstein'
  | 'Teppich|Laminat'
  | 'Sonstiges';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieAusstattungFussbodenbelag = {
  KunststoffPVC: 'Kunststoff/PVC' as ImmobilieAusstattungFussbodenbelag,
  Fliesen: 'Fliesen' as ImmobilieAusstattungFussbodenbelag,
  ParkettNaturstein: 'Parkett|Naturstein' as ImmobilieAusstattungFussbodenbelag,
  TeppichLaminat: 'Teppich|Laminat' as ImmobilieAusstattungFussbodenbelag,
  Sonstiges: 'Sonstiges' as ImmobilieAusstattungFussbodenbelag,
};
