/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

/**
 * Current activity this Teilverkauf is in
 */
export type StageCurrentActivity =
  | 'Erstellung des Angebots + Versand'
  | 'Angebot nachfassen'
  | 'Angebot nachfassen - Rücksprache gewünscht'
  | 'Anfragen der Unterlagen + überprüfen'
  | 'Grundbuchauszug prüfen'
  | 'Grundbuchauszug prüfen - Korrektur'
  | 'Grundbuchauszug prüfen - 2. Prüfung'
  | 'Gutachter beauftragen'
  | 'Gutachtentermin eintragen'
  | 'Gutachtentermin nachfassen'
  | 'Gutachten wird erstellt'
  | 'Werte eintragen'
  | 'Gutachten prüfen'
  | 'Gutachten prüfen - Korrektur'
  | 'Gutachten prüfen - final'
  | 'Gutachten besprochen / Angebot verschickt'
  | 'Rücksprache mit Eigentümer (verb. Angebot)'
  | 'Rücksprache mit Eigentümer (4 Wochen)'
  | 'Interne Prüfung'
  | 'Interne Prüfung - Korrektur'
  | 'Interne Prüfung - final'
  | 'Kaufvertrag beauftragen + Terminvorschläge'
  | 'Warten bis Kaufvertrag verschickt'
  | 'ausführliche Kaufvertragsbesprechung + Absprache Notartermin'
  | 'Rücksprache mit Eigentümer (Kaufvertrag)'
  | 'Rücksprache mit Eigentümer (2 Wochen)'
  | 'Notartermin vereinbaren'
  | 'MORGEN Notartermin'
  | 'Opportunity schließen  wenn OK'
  | 'Bitte Absage senden und Opportunity schließen'
  | 'Bezahlt';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StageCurrentActivity = {
  Erstellung_des_Angebots__Versand: 'Erstellung des Angebots + Versand' as StageCurrentActivity,
  Angebot_nachfassen: 'Angebot nachfassen' as StageCurrentActivity,
  'Angebot_nachfassen_-_Rcksprache_gewnscht': 'Angebot nachfassen - Rücksprache gewünscht' as StageCurrentActivity,
  Anfragen_der_Unterlagen__berprfen: 'Anfragen der Unterlagen + überprüfen' as StageCurrentActivity,
  Grundbuchauszug_prfen: 'Grundbuchauszug prüfen' as StageCurrentActivity,
  'Grundbuchauszug_prfen_-_Korrektur': 'Grundbuchauszug prüfen - Korrektur' as StageCurrentActivity,
  'Grundbuchauszug_prfen_-_2_Prfung': 'Grundbuchauszug prüfen - 2. Prüfung' as StageCurrentActivity,
  Gutachter_beauftragen: 'Gutachter beauftragen' as StageCurrentActivity,
  Gutachtentermin_eintragen: 'Gutachtentermin eintragen' as StageCurrentActivity,
  Gutachtentermin_nachfassen: 'Gutachtentermin nachfassen' as StageCurrentActivity,
  Gutachten_wird_erstellt: 'Gutachten wird erstellt' as StageCurrentActivity,
  Werte_eintragen: 'Werte eintragen' as StageCurrentActivity,
  Gutachten_prfen: 'Gutachten prüfen' as StageCurrentActivity,
  'Gutachten_prfen_-_Korrektur': 'Gutachten prüfen - Korrektur' as StageCurrentActivity,
  'Gutachten_prfen_-_final': 'Gutachten prüfen - final' as StageCurrentActivity,
  Gutachten_besprochen__Angebot_verschickt: 'Gutachten besprochen / Angebot verschickt' as StageCurrentActivity,
  Rcksprache_mit_Eigentmer_verb_Angebot: 'Rücksprache mit Eigentümer (verb. Angebot)' as StageCurrentActivity,
  Rcksprache_mit_Eigentmer_4_Wochen: 'Rücksprache mit Eigentümer (4 Wochen)' as StageCurrentActivity,
  Interne_Prfung: 'Interne Prüfung' as StageCurrentActivity,
  'Interne_Prfung_-_Korrektur': 'Interne Prüfung - Korrektur' as StageCurrentActivity,
  'Interne_Prfung_-_final': 'Interne Prüfung - final' as StageCurrentActivity,
  Kaufvertrag_beauftragen__Terminvorschlge: 'Kaufvertrag beauftragen + Terminvorschläge' as StageCurrentActivity,
  Warten_bis_Kaufvertrag_verschickt: 'Warten bis Kaufvertrag verschickt' as StageCurrentActivity,
  ausfhrliche_Kaufvertragsbesprechung__Absprache_Notartermin:
    'ausführliche Kaufvertragsbesprechung + Absprache Notartermin' as StageCurrentActivity,
  Rcksprache_mit_Eigentmer_Kaufvertrag: 'Rücksprache mit Eigentümer (Kaufvertrag)' as StageCurrentActivity,
  Rcksprache_mit_Eigentmer_2_Wochen: 'Rücksprache mit Eigentümer (2 Wochen)' as StageCurrentActivity,
  Notartermin_vereinbaren: 'Notartermin vereinbaren' as StageCurrentActivity,
  MORGEN_Notartermin: 'MORGEN Notartermin' as StageCurrentActivity,
  Opportunity_schlieen__wenn_OK: 'Opportunity schließen  wenn OK' as StageCurrentActivity,
  Bitte_Absage_senden_und_Opportunity_schlieen: 'Bitte Absage senden und Opportunity schließen' as StageCurrentActivity,
  Bezahlt: 'Bezahlt' as StageCurrentActivity,
};
