import { ReactNode } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from '../../hooks';

export interface HeroTitleProps {
  title: string[];
  underline?: string;
}

export const Title = ({ title, underline }: HeroTitleProps) => {
  const { smallerMd } = useMediaQuery();
  const textDivs = [] as ReactNode[];

  title.forEach((string: string, index: number) => {
    const text = string;
    if (underline && string.includes(underline)) {
      const split = text.split(underline);
      textDivs.push(
        <div className="text-white" key={index}>
          {split[0]}
          <span
            className={classNames('text-gold whitespace-nowrap leading-relaxed ', smallerMd ? '' : 'underline--wave')}
          >
            {underline}
          </span>
          {split[1]}
        </div>
      );
      return;
    }
    textDivs.push(
      <span key={index} className="text-white">
        {text}
      </span>
    );
  });

  return (
    <h1 className="font-headline text-navy-navy mt-3 text-2xl font-extrabold sm:text-3xl md:text-4xl">{textDivs}</h1>
  );
};
