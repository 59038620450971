import { CheckIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { Field, FieldHookConfig, useField } from 'formik';
import classNames from 'classnames';
import { useState } from 'react';
import { getHighlightedText } from '../../utils';
import { Info } from '../../02_composite';
import { useMediaQuery } from '../../hooks';

type LabelColors = 'grey' | 'white';

interface ValidationTextProps {
  text: string;
  highlightedPart: string;
}

interface CriteriaProps {
  criterion: string;
  checkValue: number | RegExp;
  checkType: string;
}

interface TooltipProps {
  hasTooltip: boolean;
  tooltipContent?: string;
}

interface InputFieldProps {
  labelColor?: LabelColors;
  labelBold?: boolean;
  infoMessage?: ValidationTextProps;
  allCriteriaItems?: CriteriaProps[];
  hasSuccessMarker?: boolean;
  disableErrorMessages?: boolean;
  errorMessage?: string;
  tooltip?: TooltipProps;
}

export function InputFieldPassword(
  props: React.HTMLProps<HTMLInputElement> & FieldHookConfig<string | number | unknown> & InputFieldProps
) {
  const { smallerXs } = useMediaQuery();
  const [showInfoText, setShowInfoText] = useState(false);
  const [isOutOfFocusFirstTime, setIsOutOfFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [field, meta] = useField(props);
  const {
    labelColor,
    labelBold,
    infoMessage,
    allCriteriaItems,
    hasSuccessMarker,
    disableErrorMessages = false,
    errorMessage,
    tooltip,
    ...restProps
  } = props;

  const showError = Boolean((meta.error || errorMessage) && meta.touched);
  const showSuccess = Boolean(
    !meta.error && !errorMessage && isOutOfFocusFirstTime && hasSuccessMarker && (meta.value as string)
  );
  const iconClasses = classNames(
    showError && '!text-red-secondary',
    showSuccess && '!text-green-500',
    'text-coolGray-500 h-5 w-5 mb-0'
  );

  const IsCriterionFulfilled = (inputValue: string, checkType: string, checkValue: number | RegExp) => {
    switch (checkType) {
      case 'min':
        return inputValue.length >= (checkValue as number);
      case 'match':
        return (checkValue as RegExp).test(inputValue);
    }
    return false;
  };

  return (
    <div className="font-body">
      <div className="flex flex-row">
        <label
          className={classNames(
            'block',
            labelColor === 'white' ? 'text-white' : 'text-navy',
            labelBold ? 'font-body-semibold' : 'font-body-medium',
            smallerXs && tooltip?.hasTooltip && 'pr-1'
          )}
          htmlFor={props.id || props.name}
        >
          {props.label}
        </label>
        {smallerXs && tooltip?.hasTooltip && <Info content={tooltip.tooltipContent} variant="tooltip" />}
      </div>

      <div className="relative mt-1 rounded-md shadow-sm">
        <button
          tabIndex={-1}
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 left-0 flex items-center pl-5"
          type="button"
        >
          {showPassword ? (
            <EyeOffIcon className={iconClasses} aria-hidden="true" />
          ) : (
            <EyeIcon className={iconClasses} aria-hidden="true" />
          )}
        </button>

        <Field
          {...field}
          {...restProps}
          className={classNames(
            showError && 'placeholder:!text-red-secondary text-red-secondary border-red-500 bg-red-50',
            showSuccess && 'border-green-500 bg-green-50 pr-12 text-green-500 placeholder:!text-green-500',
            'text-navy border-coolGray-300 block w-full rounded-md border py-3 pl-12 text-base',
            'placeholder:text-coolGray-400 focus:border-cyan-700 focus:outline-0 focus:ring-0',
            restProps?.className
          )}
          type={showPassword ? 'text' : 'password'}
          onFocus={() => {
            setShowInfoText(true);
          }}
          onBlur={(event: unknown) => {
            field.onBlur(event);
            setShowInfoText(false);
            setIsOutOfFocus(true);
          }}
        />
        {showSuccess && (
          <CheckIcon
            className={classNames(
              'pointer-events-none absolute inset-y-0 right-5 my-auto h-6 w-6',
              props?.disabled ? '!text-coolGray-400' : 'text-green-500'
            )}
            aria-hidden="true"
          />
        )}
      </div>
      {!showError && !allCriteriaItems && !showSuccess && showInfoText && infoMessage && (
        <p
          className="text-coolGray-500 font-body mt-2 text-sm antialiased"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(infoMessage.text, infoMessage.highlightedPart, 'coolGray-500', {
              fontFamily: 'font-body',
              isBold: true,
            }),
          }}
        />
      )}
      {!smallerXs && disableErrorMessages && !infoMessage && allCriteriaItems && (
        <div className="text-coolGray-500 font-body mt-2 space-y-2 text-sm antialiased">
          {allCriteriaItems.map((item, index) => (
            <div className="inline-flex items-center space-x-2" key={`criterion-${index}`}>
              <p
                className={classNames(
                  IsCriterionFulfilled(meta.value as string, item.checkType, item.checkValue)
                    ? 'basis-11/12 text-green-500'
                    : 'text-coolGray-500'
                )}
              >
                {item.criterion}
              </p>
              {IsCriterionFulfilled(meta.value as string, item.checkType, item.checkValue) && (
                <CheckIcon className="h-4 w-4 basis-1/12 text-green-500" aria-hidden="true" />
              )}
            </div>
          ))}
        </div>
      )}
      {!disableErrorMessages && !allCriteriaItems && showError && (
        <div className="text-red-secondary">
          <p data-testid={`${props.name}-error`} className="mt-2 text-sm" id="error">
            {meta.error || errorMessage}
          </p>
        </div>
      )}
    </div>
  );
}
