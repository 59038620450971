import { AuthState, CurrentUser, DtkConfig } from '../types';

import Amplify from 'aws-amplify';
import create from 'zustand';

interface AuthStateStore {
  authState: AuthState;
  user?: CurrentUser;
  config?: DtkConfig;
  lastChange?: Date;
  setCurrentUser: (user: CurrentUser) => void;
  setLoggedOut: () => void;
  changed: () => void;
  setConfig: (state: DtkConfig) => void;
}

export const useAuthState = create<AuthStateStore>((set) => ({
  authState: AuthState.Pending,
  setLoggedOut: () => set((state) => ({ ...state, user: undefined, authState: AuthState.SignedOut })),
  setCurrentUser: (user: CurrentUser) =>
    set((state) => ({ ...state, authState: AuthState.SignedIn, user: { ...user } })),
  changed: () => set((state) => ({ ...state, lastChange: new Date() })),
  setConfig: (config: DtkConfig) => {
    const amplifyAuthConfig = {
      region: config.region,
      userPoolId: config.cognitoUserPool,
      userPoolWebClientId: config.cognitoClientId,
    };
    // not nice to do this side effect, but this is the central position
    Amplify.configure({
      Auth: amplifyAuthConfig,
    });
    set((state) => ({ ...state, config: { ...config } }));
  },
}));
