import { useCountdown, UseCountdownProps as HookTimerProps } from '../hooks/useCountdown';

export const Timer = ({ countdown, handleCallback, classNames }: TimerProps) => {
  const time = useCountdown({ countdown, handleCallback });

  return (
    <span className={classNames}>
      {time.minutes.toString().padStart(2, '0')}:{time.seconds.toString().padStart(2, '0')}
    </span>
  );
};

export type TimerProps = { classNames?: string } & HookTimerProps;
