import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

const ProductPostBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} className="product-post" key={blok._uid} data-test="product-post">
      {blok?.blocks?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </div>
  );
};

export default ProductPostBlok;
