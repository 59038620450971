import { log as logger } from '@dtk/logging';
import { CognitoClientId, CognitoUserPoolId, EMailAddress } from '@dtk/types';
import { AuthenticationCard, validationMessages } from '@dtk/ui-components';
import { trackUserLoginStart, trackUserLoginSuccess } from '@dtk/user-tracking';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import { ChallengeAnswer, ChallengeResponseAwaiter } from './components/ChallengeResponseAwaiter';
import { MagicLinkForm } from './components/MagicLinkForm';
import './MagicLink.module.scss';
import Labels from './components/MagicLinkRequestForm.labels';

/**
 * This map holds the cognito data to prevent persisting mutable state.
 */
const cognitoData = {} as {
  user?: CognitoUser;
  userPool?: CognitoUserPool;
};

export enum MagicLinkRequestPhase {
  INITIAL,
  REQUESTING,
  PENDING,
  WAITING_FOR_CHALLENGE,
  EXPIRED,
  ERROR,
}

const log = logger.child({ module: 'MagicLink' });

export function MagicLink(props: MagicLinkProps) {
  const [state, setState] = useState({
    phase: MagicLinkRequestPhase.INITIAL,
    headline: 'Anmeldung zu Ihrem Kundenportal',
    subline: 'Geben Sie Ihre E-Mail-Adresse ein.',
    badge: 'Anmeldung',
    mail: props.mail,
    tokenCleared: false,
  } as MagicLinkState);

  useEffect(() => {
    cognitoData.userPool = new CognitoUserPool({ UserPoolId: props.userPoolId, ClientId: props.clientId });
    cognitoData.user = cognitoData.userPool.getCurrentUser() || undefined;
  }, [props.userPoolId, props.clientId]);

  useEffect(() => {
    if (!state.mail || !cognitoData.userPool) {
      return;
    }
    cognitoData.user = new CognitoUser({ Username: state.mail, Pool: cognitoData.userPool });
  }, [props.userPoolId, state.mail]);

  useEffect(() => {
    log.debug('Magic link state changed to %s', state.phase);
    if (state.phase === MagicLinkRequestPhase.REQUESTING) {
      setState((state) => ({
        ...state,
        headline: 'Dieses Fenster bitte nicht schließen, sie werden gleich angemeldet.',
      }));
      requestAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.phase]);

  useEffect(() => {
    if (state.tokenCleared === false) {
      return;
    }
    if (props.oneTimeToken) {
      setState((state) => ({
        ...state,
        phase: MagicLinkRequestPhase.PENDING,
        headline: Labels.mailSent,
      }));
      cognitoData.user?.sendCustomChallengeAnswer(cleanOnetimeToken(props.oneTimeToken), authenticationCallback);
      return;
    }
    cognitoData.user?.sendCustomChallengeAnswer('mail', authenticationCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tokenCleared]);

  const timerExpired = () => {
    setState(
      produce((draft) => {
        draft.phase = MagicLinkRequestPhase.EXPIRED;
        draft.headline = 'Ihr Link ist leider abgelaufen.';
        draft.subline = 'Versuchen Sie es noch einmal.';
        draft.badge = 'Link abgelaufen';
      })
    );
  };

  const authenticationCallback = {
    onSuccess: () => {
      log.debug('Successfully authenticated');
      if (props.onSuccess) {
        props.onSuccess();
        trackUserLoginSuccess();
      }
    },
    customChallenge: (challengeProps: { endpoint: string }) => {
      setState(
        produce((draft) => {
          draft.tokenCleared = true;
          draft.phase = MagicLinkRequestPhase.WAITING_FOR_CHALLENGE;
          draft.challengeUrl = new URL(challengeProps.endpoint);
          draft.subline = Labels.mailSent;
          draft.badge = 'Verifizierung';
        })
      );
    },
    onFailure: () => {
      setState(
        produce((draft) => {
          draft.phase = MagicLinkRequestPhase.ERROR;
          draft.customErrorMsg = validationMessages.error.loginFeedback.magicLinkText;
        })
      );
    },
  };

  const onChallengeAnswered = (answer: ChallengeAnswer) => {
    if (state.phase !== MagicLinkRequestPhase.WAITING_FOR_CHALLENGE) {
      log.warn('Received answer outside of WAITING_FOR_CHALLENGE phase');
      return;
    }
    cognitoData.user?.sendCustomChallengeAnswer(answer, authenticationCallback);
  };

  const onChallengeError = () => {
    setState(
      produce((draft) => {
        draft.phase = MagicLinkRequestPhase.ERROR;
      })
    );
  };

  const requestAuth = () => {
    log.debug('🧚‍♀️ Requesting magic link for user %s', state.mail);
    if (!state.mail) {
      return;
    }
    trackUserLoginStart();
    const metadata: Record<string, string> = {};
    if (props.oneTimeToken) {
      metadata.oneTimeToken = String(props.oneTimeToken);
    }
    const authDetails = new AuthenticationDetails({
      Username: state.mail,
      ClientMetadata: metadata,
    });
    log.debug('Current user: %s', String(cognitoData.user?.getUsername()));
    cognitoData.user?.initiateAuth(authDetails, authenticationCallback);
    setState(
      produce((draft) => {
        draft.phase = MagicLinkRequestPhase.PENDING;
      })
    );
  };

  async function requestMagicLink(mail: EMailAddress) {
    setState(
      produce((draft) => {
        draft.phase = MagicLinkRequestPhase.REQUESTING;
        draft.mail = mail.toLowerCase();
        draft.customErrorMsg = undefined;
      })
    );
  }

  useEffect(() => {
    if (!props.oneTimeToken) {
      return;
    }
    requestAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oneTimeToken]);

  return (
    <div>
      <AuthenticationCard
        overline={state.badge}
        headline={state.headline}
        subtitle={state.subline}
        footerVariant="no-account"
        setLoginType={props.setLoginType}
        secondaryButton={{ text: 'Mit Passwort anmelden', loginType: 'credentials' }}
      >
        <MagicLinkForm
          onSubmit={(values) => requestMagicLink(values.email)}
          phase={state.phase}
          customErrorMsg={state.customErrorMsg}
          onTimerExpired={timerExpired}
        />
      </AuthenticationCard>

      {state.phase === MagicLinkRequestPhase.WAITING_FOR_CHALLENGE && state.mail && state.challengeUrl && (
        <ChallengeResponseAwaiter
          url={state.challengeUrl}
          onChallengeAnswered={(answer) => onChallengeAnswered(answer)}
          onError={() => onChallengeError()}
        />
      )}
    </div>
  );
}

/**
 * Emarsys appends tracking params with an additional ?, so we need to fix those as a workaround for url based login.
 *
 * @param oneTimeToken
 * @returns
 */
function cleanOnetimeToken(oneTimeToken: string): string {
  return oneTimeToken.split('?', 2)[0];
}
export type ButtonLabel = '';

interface MagicLinkState {
  phase: MagicLinkRequestPhase;
  mail?: EMailAddress;
  challengeUrl?: URL;
  customErrorMsg?: string;
  headline: string;
  subline: string;
  badge: string;
  // Indicates a one-time-token query has been cleared by sending an
  // empty request
  tokenCleared: boolean;
}

export interface MagicLinkProps {
  mail?: EMailAddress;
  oneTimeToken?: string;
  userPoolId: CognitoUserPoolId;
  clientId: CognitoClientId;
  onSuccess?: { (): void };
  setLoginType: (loginType: LoginType) => void;
}

type LoginType = 'magiclink' | 'credentials' | 'new-password' | 'registration';

export default MagicLink;
