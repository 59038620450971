import { Popover, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import { PreferedPosition } from '.';

export const Tooltip = ({
  content,
  colorVariant,
  isOpen,
  events,
  preferedPosition: placement = 'top',
  buttonStyle,
}: TooltipProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 10],
        },
      },
    ],
  });

  const colors = getThemeColors(colorVariant);

  return (
    <Popover>
      <Popover.Button {...events} as="div" style={{ pointerEvents: 'all', ...buttonStyle }} ref={setReferenceElement}>
        <button {...events}>
          <InformationCircleIcon className="h-6 w-6" />
        </button>
      </Popover.Button>

      <Transition show={isOpen}>
        <Popover.Panel {...events} className="z-50" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {({ close }) => (
            <>
              {/* Tooltip */}
              <div
                className={`relative z-50 flex max-w-[300px] break-words rounded-xl ${colors.background} ${colors.text} text-left text-sm font-normal leading-tight no-underline shadow-md`}
              >
                <div className="px-4 py-2">{content}</div>
              </div>
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export interface TooltipProps {
  content: string | ReactNode;
  header?: string;
  isOpen: boolean;
  colorVariant: TooltipColor;
  events: {
    ['onMouseEnter']?: () => void;
    ['onMouseLeave']?: () => void;
    ['onClick']?: () => void;
  };
  preferedPosition?: PreferedPosition;
  buttonStyle?: React.CSSProperties;
}
export type TooltipColor = 'light' | 'dark' | 'gray';

const getThemeColors = (colorVariant: string) => {
  let background = '';
  let text = '';
  if (colorVariant === 'dark') {
    background = classNames('bg-coolGray-800');
    text = classNames('text-white');
  }
  if (colorVariant === 'light') {
    background = classNames('bg-white');
    text = classNames('text-navy');
  }
  if (colorVariant === 'gray') {
    background = classNames('bg-coolGray-100');
    text = classNames('text-coolGray-500');
  }

  return { background, text };
};
