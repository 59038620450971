import '../styles/styles.scss';
import '@dtk/default-theme';
import '@dtk/auth/provider/style';

import { apiPlugin, storyblokInit } from '@storyblok/react';
import { marktplatzSbComponents, sharedStoryblokComponents } from '@dtk/storyblok';

import { AppProps } from 'next/app';
import { DataLayerScript } from '@dtk/user-tracking';
import Head from 'next/head';
import Layout from '../components/Layout';
import { Provider } from '@dtk/auth';
import SmallFooterPortal from '../components/SmallFooterPortal';
import { StageProvider } from '@dtk/query';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-KWF3DCF',
};

const components = {
  ...sharedStoryblokComponents,
  ...marktplatzSbComponents,
};

storyblokInit({
  accessToken: '3koxfeFUxXuPQrk6kSVJxAtt',
  use: [apiPlugin],
  components,
});

const App = ({ Component, pageProps }: AppProps) => {
  if (globalThis.document) {
    TagManager.initialize(tagManagerArgs);
  }
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Mein Teilverkauf</title>
      </Head>
      <DataLayerScript containerId="5EZLMRvWP"></DataLayerScript>
      <Provider>
        <StageProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </StageProvider>
      </Provider>
      <SmallFooterPortal />
    </>
  );
};

export default App;
