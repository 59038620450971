import { useGetCustomer } from '@dtk/query';
import { GreetingsHeroSection, GreetingsHeroSectionProps, HeroSectionTextColor, defaultData } from '@dtk/ui-components';

export function ClubHeroSectionWrapper({
  externalData,
  gradientbackgroundClassNames,
  textColor,
}: GreetingsHeroSectionProps) {
  const { data, isLoading, isError } = useGetCustomer();

  if (isLoading || isError || !externalData) {
    return <GreetingsHeroSection externalData={defaultData} />;
  }

  const customer = data?.data;
  const heroGradientbackgroundClassNames = 'linear-gradient(90deg, #F5F0E6 55%, #CBE2D8 100%)';
  const heroTextColor: HeroSectionTextColor = 'navy-navy';

  return (
    <GreetingsHeroSection
      externalData={externalData}
      customerSalutation={customer?.salutation}
      customerLastname={customer?.lastname}
      gradientbackgroundClassNames={heroGradientbackgroundClassNames}
      textColor={heroTextColor}
    />
  );
}
