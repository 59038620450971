/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieModernisierungModernisierungHeizung = 'Keine' | 'Teilweise' | 'Komplett';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieModernisierungModernisierungHeizung = {
  Keine: 'Keine' as ImmobilieModernisierungModernisierungHeizung,
  Teilweise: 'Teilweise' as ImmobilieModernisierungModernisierungHeizung,
  Komplett: 'Komplett' as ImmobilieModernisierungModernisierungHeizung,
};
