/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieBauweiseKellerausbaustufe = 'Nicht ausgebaut' | 'Teilweise ausgebaut' | 'Komplett ausgebaut';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieBauweiseKellerausbaustufe = {
  Nicht_ausgebaut: 'Nicht ausgebaut' as ImmobilieBauweiseKellerausbaustufe,
  Teilweise_ausgebaut: 'Teilweise ausgebaut' as ImmobilieBauweiseKellerausbaustufe,
  Komplett_ausgebaut: 'Komplett ausgebaut' as ImmobilieBauweiseKellerausbaustufe,
};
