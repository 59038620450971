import { AmazonRegion, CognitoClientId, CognitoUserPoolId } from '@dtk/types';
import { AuthenticationType, Navigation, useMediaQuery } from '@dtk/ui-components';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { AuthState } from '../types';
import { LoginHelp } from './LoginHelp';
import { MagicLinkDialog } from './Login/MagicLinkDialog';
import { CredentialsDialog } from './Login/CredentialsDialog';
import { NewPasswordDialog } from './Login/NewPasswordDialog';
import { CredentialsDialog as RegistrationDialog } from './Registration/CredentialsDialog';

export function AuthenticationDialog({ authState, children, config }: AuthDialogPageProps) {
  const { largerLg } = useMediaQuery();
  const { pathname, query } = useRouter();
  const oneTimeToken = query.token as string;
  const [authenticationType, setAuthenticationType] = useState<AuthenticationType>(
    getPreferredAuthenticationType(oneTimeToken)
  );

  if (authState === AuthState.SignedIn) {
    return <>{children} </>;
  }

  const setLoginTypeFunc = (type: AuthenticationType) => {
    localStorage.setItem('DTK-new-preferredAuthenticationTypeType', type);
    setAuthenticationType(type);
  };

  return (
    <>
      <Navigation homeLink="https://deutsche-teilkauf.de" stage="Initial" routes={[]} pathname={pathname} />
      {authenticationType === 'registration' && <RegistrationDialog setLoginType={setLoginTypeFunc} />}
      {((authenticationType === 'magiclink' && !largerLg) || authenticationType === 'credentials') && (
        <CredentialsDialog setLoginType={setLoginTypeFunc} />
      )}
      {authenticationType === 'magiclink' && largerLg && (
        <MagicLinkDialog config={config} setLoginType={setLoginTypeFunc} />
      )}
      {authenticationType === 'new-password' && <NewPasswordDialog setLoginType={setLoginTypeFunc} />}
      <LoginHelp />
    </>
  );
}

const getPreferredAuthenticationType = (oneTimeToken?: string): AuthenticationType => {
  if (oneTimeToken) {
    return 'magiclink';
  }

  const authenticationType = localStorage.getItem('DTK-new-preferredAuthenticationTypeType') as AuthenticationType;
  const defaultAuthenticationType = 'credentials';

  if (!authenticationType || authenticationType === 'new-password' || authenticationType === 'registration') {
    return defaultAuthenticationType;
  }

  return authenticationType;
};

export interface DtkConfig {
  cognitoUserPool: CognitoUserPoolId;
  cognitoClientId: CognitoClientId;
  region: AmazonRegion;
}

export interface AuthDialogPageProps {
  authState: AuthState;
  children: React.ReactNode;
  config: DtkConfig;
}
