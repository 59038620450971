import { ViewGridIcon, KeyIcon, PaperClipIcon, CalculatorIcon, ChartBarIcon } from '@heroicons/react/outline';
import { StageStatus } from '@dtk/query';
import { NavigationRoutesProps } from '@dtk/ui-components';

export const customerRoutes: NavigationRoutesProps[] = [
  {
    href: '/',
    name: 'Übersicht',
    guideTourId: 'nav-uebersicht',
    stage: [
      StageStatus.Initial,
      StageStatus.Bewertung,
      StageStatus.unverbindliches_Angebot,
      StageStatus.Gutachten,
      StageStatus.verbindliches_Angebot,
      StageStatus.Kaufvertrag,
      StageStatus.Angebot_angenommen,
    ],
    icon: <ViewGridIcon className="h-6 w-6" />,
  },
  {
    href: '/meineimmobilie',
    name: 'Meine Immobilie',
    guideTourId: 'nav-immobilie',
    stage: [
      StageStatus.Initial,
      StageStatus.Bewertung,
      StageStatus.unverbindliches_Angebot,
      StageStatus.Gutachten,
      StageStatus.verbindliches_Angebot,
      StageStatus.Kaufvertrag,
      StageStatus.Angebot_angenommen,
    ],
    icon: <KeyIcon className="h-6 w-6" />,
  },
  {
    href: '/dokumente',
    name: 'Objektunterlagen',
    guideTourId: 'nav-documents',
    stage: [
      StageStatus.Bewertung,
      StageStatus.unverbindliches_Angebot,
      StageStatus.Gutachten,
      StageStatus.verbindliches_Angebot,
      StageStatus.Kaufvertrag,
      StageStatus.Angebot_angenommen,
    ],
    icon: <PaperClipIcon className="h-6 w-6" />,
  },
  {
    href: '/rechner',
    name: 'Vergleichen & Berechnen',
    guideTourId: 'nav-calculator',
    stage: [
      StageStatus.unverbindliches_Angebot,
      StageStatus.Gutachten,
      StageStatus.verbindliches_Angebot,
      StageStatus.Kaufvertrag,
      StageStatus.Angebot_angenommen,
    ],
    icon: <CalculatorIcon className="h-6 w-6" />,
  },
  {
    href: '/daten-fakten',
    name: 'Daten & Fakten',
    guideTourId: 'nav-facts',
    stage: [
      StageStatus.unverbindliches_Angebot,
      StageStatus.Gutachten,
      StageStatus.verbindliches_Angebot,
      StageStatus.Kaufvertrag,
      StageStatus.Angebot_angenommen,
    ],
    icon: <ChartBarIcon className="h-6 w-6" />,
  },
];
