/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieBasisdatenGeschosslage =
  | 'Untergeschoss'
  | 'Erdgeschoss'
  | '1_Obergeschoss'
  | '2_Obergeschoss'
  | '3_Obergeschoss'
  | '4_Obergeschoss'
  | '5_Obergeschoss'
  | '6_Obergeschoss'
  | '7_Obergeschoss'
  | 'ueber_8_Geschosse';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieBasisdatenGeschosslage = {
  Untergeschoss: 'Untergeschoss' as ImmobilieBasisdatenGeschosslage,
  Erdgeschoss: 'Erdgeschoss' as ImmobilieBasisdatenGeschosslage,
  '1_Obergeschoss': '1_Obergeschoss' as ImmobilieBasisdatenGeschosslage,
  '2_Obergeschoss': '2_Obergeschoss' as ImmobilieBasisdatenGeschosslage,
  '3_Obergeschoss': '3_Obergeschoss' as ImmobilieBasisdatenGeschosslage,
  '4_Obergeschoss': '4_Obergeschoss' as ImmobilieBasisdatenGeschosslage,
  '5_Obergeschoss': '5_Obergeschoss' as ImmobilieBasisdatenGeschosslage,
  '6_Obergeschoss': '6_Obergeschoss' as ImmobilieBasisdatenGeschosslage,
  '7_Obergeschoss': '7_Obergeschoss' as ImmobilieBasisdatenGeschosslage,
  ueber_8_Geschosse: 'ueber_8_Geschosse' as ImmobilieBasisdatenGeschosslage,
};
