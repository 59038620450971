export default {
  mail: 'Ihre E-Mail-Adresse',
  mailSent: 'Wir haben Ihnen eine E-Mail geschickt.',
  requestLink: 'Anmeldelink erhalten',
  resendRequestLink: 'Anmeldelink erneut senden',
  wrongEmail: 'Diese Email ist uns nicht bekannt. Bitte überprüfen Sie Ihre Eingabe oder erstellen Sie ein Konto.',
  invalidMail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
  mailRequired: 'Bitte geben Sie eine E-Mail-Adresse ein.',
  systemError: 'Login aktuell nicht möglich.',
  noAccount: 'Sie haben kein Konto?',
};
