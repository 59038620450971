import { UserIcon } from '@heroicons/react/outline';
import { NavigationAreasProps, IconDiscount, IconTeilverkauf } from '@dtk/ui-components';
import { clubRoutes } from './clubRoutes';
import { customerRoutes } from './customerRoutes';
import { profileRoutes } from './profileRoutes';

export const mainRoutes: NavigationAreasProps[] = [
  {
    id: 'dtk',
    href: '/',
    name: 'Mein Teilverkauf',
    icon: <IconTeilverkauf className="h-6 w-6" />,
    routes: customerRoutes,
  },
  {
    id: 'club',
    href: '/dtk-club/uebersicht',
    name: 'Mein DTK-Club',
    icon: <IconDiscount className="h-6 w-6" />,
    routes: clubRoutes,
  },
  {
    id: 'profile',
    href: '/personal',
    name: 'Mein Bereich',
    icon: <UserIcon className="h-6 w-6" />,
    routes: profileRoutes,
  },
];
