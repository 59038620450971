import { useAppConfig } from '@dtk/config';
import {
  AuthenticationCard,
  AuthenticationType,
  validationMessages,
  AuthenticationCardProps,
} from '@dtk/ui-components';
import axios from 'axios';
import { useState } from 'react';
import {
  RegistrationCustomerInfoFields,
  RegistrationCredentialsForm,
  REGISTRATION_FORM_LOADING_DURATION,
} from './form';

interface CredentialLoginProps {
  setLoginType: (loginType: AuthenticationType) => void;
}
export function CredentialsDialog({ setLoginType }: CredentialLoginProps) {
  const { config } = useAppConfig();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isRegistrationRequested, setRegistrationRequested] = useState<boolean>(false);

  const [state, setState] = useState({
    overline: 'Registrierung',
    headline: 'Konto erstellen',
    subtitle: 'Geben Sie bitte Ihre Daten ein.',
    footerVariant: 'account',
    setLoginType: setLoginType,
  } as AuthenticationCardProps);

  function signUp(values: RegistrationCustomerInfoFields) {
    const customerInformation = {
      salutation: values.salutation,
      lastname: values.lastname,
      email: values.email,
    };

    axios
      .post(config['API_BASE'] + '/public/register-club-customer', customerInformation, {})
      .then(() => {
        setTimeout(() => {
          setRegistrationRequested(true);
          setState({
            ...state,
            headline: 'Dieses Fenster bitte nicht schließen.',
            subtitle: 'Wir haben Ihnen eine E-Mail geschickt.',
            footerVariant: 'none',
          });
        }, REGISTRATION_FORM_LOADING_DURATION);
      })
      .catch(() => {
        setErrorMessage(validationMessages.error.portalFormBanner.text);
      });
  }

  return (
    <AuthenticationCard {...state}>
      <RegistrationCredentialsForm
        isRegistrationRequested={isRegistrationRequested}
        errorMessage={errorMessage}
        onSubmit={(values) => signUp(values)}
      />
    </AuthenticationCard>
  );
}
