import classNames from 'classnames';
import { StatusPill, StatusPillProps } from '../../01_elements';
import { CtaButton, CtaButtonProps } from './CtaButton';
import { HeroTitleProps, Title } from './Title';
import { Content } from './Content';

export const HeroSection = ({
  status,
  title,
  backgroundColor,
  imagePath,
  imagePosition = 'center',
  ctaButton,
  content,
  className,
}: HeroSectionProps) => {
  const backgroundClassName = classNames('relative', backgroundColor ? backgroundColor : 'bg-navy-800');
  return (
    <div className={classNames(backgroundClassName, className)}>
      <div
        className={classNames(
          'w-full px-6 py-8 text-left text-white md:py-8 lg:py-12 xl:pr-10 2xl:py-14 2xl:pr-24',
          'lg:min-h-200 xl:1/2 lg:mr-[50%] lg:ml-auto lg:w-1/2 lg:max-w-[41rem] xl:mr-[50%]'
        )}
      >
        {status && <StatusPill {...status} />}
        <Title {...title} />
        {content && <Content text={content} />}
        {ctaButton && <CtaButton {...ctaButton} />}
      </div>

      <div
        className={classNames(
          'relative h-48',
          'sm:h-64',
          'lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2 xl:w-1/2'
        )}
      >
        <img className={`absolute h-full w-full object-cover object-${imagePosition}`} src={imagePath} alt="" />
      </div>
    </div>
  );
};

type ImagePosition = 'top' | 'center' | 'bottom';

export interface HeroSectionProps {
  status?: StatusPillProps;
  title: HeroTitleProps;
  backgroundColor?: string;
  imagePath: string;
  imagePosition?: ImagePosition;
  ctaButton?: CtaButtonProps;
  content?: string | React.ReactNode;
  className?: string;
}
