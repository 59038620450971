import { ReactNode, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Tooltip, TooltipColor } from './Tooltip';
import { PopOver, PopOverColor } from './PopOver';
import { SlideOver } from './SlideOver';
import { useMediaQuery } from '../hooks';

export const Info = ({
  headline = '',
  content,
  action = 'click',
  variant,
  colorVariant = 'gray',
  preferedPosition = 'top',
  buttonStyle,
}: InfoProps) => {
  const { smallerMd } = useMediaQuery();
  const [open, setOpen] = useState(false);

  const getActionHandler = () => {
    if (action === 'click') {
      return { onClick: () => setOpen(!open) };
    }
    return {
      onMouseEnter: () => setOpen(true),
      onMouseLeave: () => setOpen(false),
    };
  };

  if (variant === 'slideOver')
    return (
      <>
        <SlideOver isOpen={open} setOpen={setOpen} headline={headline}>
          {content}
        </SlideOver>
        <button onClick={() => setOpen(!open)} style={buttonStyle}>
          <InformationCircleIcon className="h-6 w-6" />
        </button>
      </>
    );

  if (variant === 'popOver') {
    return (
      <PopOver
        isOpen={open}
        header={headline}
        content={content}
        setOpen={setOpen}
        colorVariant={colorVariant}
        preferedPosition={preferedPosition}
        events={getActionHandler()}
      />
    );
  }
  if (variant === 'tooltip') {
    return (
      <Tooltip
        isOpen={open}
        header={headline}
        content={content}
        colorVariant={colorVariant}
        preferedPosition={preferedPosition}
        events={getActionHandler()}
        buttonStyle={buttonStyle}
      />
    );
  }
  return null;
};

export interface InfoProps {
  /**
   * only popOver and slideOver do have a headline, tooltips do not.
   */
  headline?: string;
  /**
   * you can either pass a string or an entire react node as content
   */
  content: string | ReactNode;
  /**
   * can be 'click' or 'hover'.
   * Default is 'click'
   * NOTE: slideOver always reacts on click.
   */
  action?: ActionVariant;
  /**
   * you can choose between tooltip, slideOver and popOver.
   * NOTE: mobile devices always use slideOver
   *
   * - tooltips should be used for text with max. 50 letters.
   *
   * - popOvers should be use for text with more than 50 letters.
   *
   * - slideOvers should be used is used when the info refers to the entire
   *   component or contains a lot of further information (buttons, further links)
   */
  variant: InfoVariant;
  colorVariant?: TooltipColor | PopOverColor;
  /**
   * if there is no place to render to info-component on the preferedPosition
   * it will automatically chose another position
   */
  preferedPosition?: PreferedPosition;
  buttonStyle?: React.CSSProperties;
}

export type InfoVariant = 'slideOver' | 'popOver' | 'tooltip';
export type ActionVariant = 'click' | 'hover';
export type PreferedPosition = 'top' | 'bottom' | 'right' | 'left' | 'auto';
