import { LockOpenIcon, LogoutIcon, MenuIcon, XIcon } from '@heroicons/react/outline';

import { MutableRefObject, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import Link from 'next/link';
import classNames from 'classnames';
import { StageContextData, StageStatus } from '@dtk/query';
import { log } from '@dtk/logging';
import { trackNavigation } from '@dtk/user-tracking';
import { DtkLogo, SmallDtkLogo } from '../../static';
import { NavigationIconWithText } from './NavigationIconWithText';
import { Button } from '../../01_elements';
import { useAppConfig } from '@dtk/config';
import { useMediaQuery } from '../../hooks';

interface NavigationLogoutButtonProps {
  id: string;
  classNames: string;
}

export interface NavigationItemProps {
  href: string;
  isActive?: boolean;
  name: string;
  stage?: StageStatus[];
  guideTourId?: string;
  icon: JSX.Element;
  id?: NavigationArea;
  disabled?: boolean;
  closeMenu: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined) => void;
}

export interface NavigationRoutesProps {
  id?: NavigationArea;
  href: string;
  name: string;
  stage?: StageStatus[];
  guideTourId?: string;
  icon: JSX.Element;
}

export interface NavigationAreasProps {
  href: string;
  name: string;
  id: NavigationArea;
  icon: JSX.Element;
  routes: NavigationRoutesProps[];
}

export interface NavigationProps {
  routes: NavigationAreasProps[];
  pathname: string;
  stage: StageStatus;
  stageContext?: StageContextData;
  homeLink?: string;
}

export type NavigationArea = 'dtk' | 'club' | 'profile' | 'website';

export function Navigation({ routes, pathname, stage, stageContext, homeLink }: NavigationProps) {
  const { config } = useAppConfig();
  const { smallerSm, largerXl } = useMediaQuery();
  const router = useRouter();

  const [selectedArea, setSelectedArea] = useState<NavigationArea>(pathname?.includes('dtk-club') ? 'club' : 'dtk');
  const interestedInPartialSale = stageContext?.interestedInPartialSale;

  const offerJourneySlug = smallerSm ? 'angebot-anfordern-mobil' : 'angebot-anfordern';
  const partialSaleWebsiteRoute: NavigationRoutesProps = {
    id: 'website',
    name: 'Teilverkauf anfragen',
    href: `${config['WEBSITE_SOURCE']}/${offerJourneySlug}/`,
    icon: <LockOpenIcon className="h-6 w-6" />,
  };

  if (pathname === '/abgemeldet' || pathname === '/welcome') {
    routes = [];
  }

  const mobileRoutes: NavigationRoutesProps[] = [];
  routes.forEach((area) => {
    mobileRoutes.push({ id: area.id, name: area.name, href: area.href, icon: area.icon });
    area.routes.forEach((route) => {
      mobileRoutes.push(route);
    });
  });
  routes.length > 0 && !interestedInPartialSale && mobileRoutes.push(partialSaleWebsiteRoute);

  const NavigationItem = ({
    href,
    isActive,
    name,
    guideTourId,
    icon,
    id,
    disabled,
    closeMenu,
  }: NavigationItemProps) => (
    <Link href={disabled ? '' : href} passHref>
      <a
        id={guideTourId}
        data-testid={href}
        href="/"
        onClick={() => {
          trackNavigation(name);
          id && setSelectedArea(id);
          closeMenu();
        }}
        className={classNames(
          disabled
            ? 'text-coolGray-400 pointer-events-none cursor-default'
            : isActive && id
            ? 'lg:bg-coolGray-100 text-cyan-secondary'
            : isActive && !id
            ? 'text-cyan-secondary'
            : 'text-navy hover:text-cyan-secondary',
          'rounded-t-md sm:px-3 lg:mt-1 lg:py-4'
        )}
        aria-current={isActive ? 'page' : undefined}
      >
        <NavigationIconWithText iconText={name} icon={icon} />
      </a>
    </Link>
  );

  const handleSignOut = async () => {
    try {
      trackNavigation('Logout');
      await Auth.signOut({ global: true });
      router.push('/abgemeldet');
    } catch (error) {
      log('error signing out: ' + JSON.stringify(error));
    }
  };

  const LogoutButton = ({ id, classNames }: NavigationLogoutButtonProps) => (
    <button id={id} key={id} type="button" onClick={handleSignOut} className={classNames}>
      <div className="flex items-center rounded-md">
        <LogoutIcon className="h-6 w-6" aria-hidden="true" />
        <p className="ml-2 text-base font-semibold">Ausloggen</p>
      </div>
    </button>
  );

  return (
    <div className="sticky top-0 z-50 min-h-full w-full bg-white">
      <Disclosure as="nav">
        {({ open, close }) => (
          <>
            <div className="text-navy lg:border-b lg:shadow-md">
              <div className="mx-auto max-w-7xl pr-2 pl-4 sm:pr-4 xl:px-0 ">
                <div className="flex h-[59px] items-center justify-between gap-4 sm:gap-0">
                  <div className="flex items-center">
                    <Link href={homeLink ? homeLink : '/'}>
                      <a href={homeLink ? homeLink : '/'} className="flex-shrink-0">
                        <DtkLogo className="xs:hidden block h-8 sm:block" loading="eager" />
                        <SmallDtkLogo className="xs:block hidden h-8 sm:hidden" loading="eager" />
                      </a>
                    </Link>
                    <div className="block">
                      <div className="ml-4 flex items-center space-x-2 sm:ml-10">
                        {routes.slice(0, 2).map((item) => (
                          <NavigationItem
                            guideTourId={''}
                            key={`${item.name}-navItem-left`}
                            href={item.href}
                            isActive={selectedArea === item.id}
                            name={item.name}
                            icon={item.icon}
                            id={item.id}
                            closeMenu={close}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="block">
                      <div className="flex items-center sm:ml-4 md:ml-6">
                        {routes.slice(routes.length - 1).map((item) => (
                          <NavigationItem
                            guideTourId={''}
                            key={`${item.name}-navItem-right`}
                            href={item.href}
                            isActive={selectedArea === item.id}
                            name={item.name}
                            icon={item.icon}
                            id={item.id}
                            closeMenu={close}
                          />
                        ))}
                      </div>
                    </div>
                    {pathname !== '/abgemeldet' && routes && routes.length > 0 && (
                      <div className="xs:pt-0 items-center pt-1 lg:hidden" id="mobile-menu">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="text-navy hover:text-cyan-secondary px-3 lg:mt-1 lg:py-4">
                          <NavigationIconWithText
                            iconText="Menü"
                            icon={open ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
                          />
                        </Disclosure.Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden border-b md:py-2 lg:block lg:p-0">
              <div className="mx-auto max-w-7xl px-4 xl:px-0">
                <div
                  className={classNames(
                    selectedArea === 'profile' ? 'justify-end' : 'justify-start',
                    'flex h-[59px] items-center'
                  )}
                >
                  <div className="flex items-center space-x-2">
                    {routes
                      .find((item) => item.id === selectedArea)
                      ?.routes.map((route) => (
                        <NavigationItem
                          guideTourId={route.guideTourId}
                          key={`${route.name}-navItem`}
                          href={route.href}
                          isActive={pathname === route.href}
                          name={route.name}
                          icon={route.icon}
                          disabled={route.stage && !route.stage?.includes(stage)}
                          closeMenu={close}
                        />
                      ))}
                    {routes.length > 0 && selectedArea === 'dtk' && !interestedInPartialSale && (
                      <Button
                        id={partialSaleWebsiteRoute.id}
                        size={largerXl ? 'base' : 'sm'}
                        icon={LockOpenIcon}
                        iconPosition="left"
                        href={partialSaleWebsiteRoute.href}
                        target="_blank"
                        rel="noopener"
                      >
                        {partialSaleWebsiteRoute.name}
                      </Button>
                    )}
                  </div>
                  {selectedArea === 'profile' && (
                    <div className="flex items-center md:ml-4">
                      {pathname !== '/abgemeldet' && homeLink === undefined && (
                        <LogoutButton id="nav-logout" classNames="hover:text-cyan-secondary text-navy" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <>
                {mobileRoutes.map((route, index) => (
                  <div key={`${route.name}-navigation-${index}`}>
                    <Link href={route.href} passHref>
                      <Disclosure.Button
                        key={`${route.name}-navigation-button`}
                        id={`${route.guideTourId ? route.guideTourId : route.name}-mobile`}
                        as="a"
                        onClick={() => {
                          trackNavigation(route.name);
                          close();
                        }}
                        className={classNames(
                          route.stage && !route.stage?.includes(stage)
                            ? 'text-coolGray-400 bg-coolGray-100 pointer-events-none cursor-default'
                            : route.id
                            ? 'text-cyan-secondary pointer-events-none'
                            : 'text-navy bg-coolGray-100',
                          'hover:text-cyan-secondary block border-b px-6 py-2.5 text-base'
                        )}
                        aria-current={route.href === pathname ? 'page' : undefined}
                      >
                        <div className="flex items-center">
                          {route.icon}
                          <p className="ml-2 text-base font-semibold">{route.name}</p>
                        </div>
                      </Disclosure.Button>
                    </Link>
                  </div>
                ))}
              </>
              {pathname !== '/abgemeldet' && (
                <LogoutButton
                  id="sign-out-usernav"
                  classNames="text-navy hover:text-cyan-secondary bg-coolGray-100 block w-full border-b px-6 py-2.5 text-base"
                />
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
