import { createContext, useContext, useState, useEffect } from 'react';
import { useGetStage } from '../../index';
import { StageCurrentActivity, StageStatus, StageTutorialStatus } from '../api';

export interface StageContextData {
  status: StageStatus | undefined;
  currentActivity: StageCurrentActivity | undefined;
  tutorialStatus: StageTutorialStatus | undefined;
  interestedInPartialSale: boolean | undefined;
  refetchCustomerStage: () => void;
}

interface StageProviderProps {
  children: React.ReactNode;
}

const StageContext = createContext<StageContextData | undefined>(undefined);

export function StageProvider(props: StageProviderProps) {
  const { children } = props;
  const { data: response, refetch, isLoading, isError } = useGetStage();
  const [context, setContext] = useState<StageContextData>({
    status: undefined,
    currentActivity: undefined,
    tutorialStatus: undefined,
    interestedInPartialSale: undefined,
    refetchCustomerStage: refetch,
  });

  useEffect(() => {
    if (!isLoading && !isError && response?.data) {
      setContext((prevContext) => ({
        ...prevContext,
        status: response.data.status,
        currentActivity: response.data.currentActivity,
        tutorialStatus: response.data.tutorialStatus,
        interestedInPartialSale: response.data.interestedInPartialSale,
      }));
    }
  }, [response, isError, isLoading]);

  return <StageContext.Provider value={context}>{children}</StageContext.Provider>;
}

export function useStageContext() {
  return useContext(StageContext);
}
