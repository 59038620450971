import { MailIcon } from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  AuthenticationType,
  Button,
  InputField,
  InputFieldPassword,
  MessageBanner,
  validationMessages,
} from '@dtk/ui-components';

const MagicLinkSchema = Yup.object().shape({
  email: Yup.string()
    .email(validationMessages.error.email.text)
    .required(validationMessages.error.defaultInputField.text),
  password: Yup.string().required(validationMessages.error.defaultInputField.text),
});

export interface CredentialsFields {
  email: string;
  password: string;
}

export const CredentialsForm = ({ onSubmit, errorMessage = '', setLoginType }: CredentialsFormProps) => {
  return (
    <Formik<CredentialsFields>
      initialValues={{ email: '', password: '' }}
      validationSchema={MagicLinkSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      <Form>
        <div className="space-y-6">
          <MessageBanner message={errorMessage} isError />
          <InputField
            data-testid="email"
            placeholder="max@mustermann.de"
            name="email"
            labelBold={true}
            type="text"
            label="Ihre E-Mail-Adresse"
            autoComplete="username"
            icon={MailIcon}
            infoMessage={validationMessages.info.email}
          />
          <InputFieldPassword
            data-testid="password"
            name="password"
            autoComplete="current-password"
            label="Ihr Passwort"
            labelBold={true}
            infoMessage={validationMessages.info.password}
          />
        </div>
        <FooterTextNoPassword setLoginType={setLoginType} />
        <Button fullWidth type="submit" data-testid="submit" variant="navy">
          Weiter
        </Button>
      </Form>
    </Formik>
  );
};

export interface CredentialsFormProps {
  onSubmit: (values: CredentialsFields) => void;
  errorMessage?: string;
  setErrorMessage?: (errorMessage: string) => void;
  setLoginType?: (loginType: AuthenticationType) => void;
}

const FooterTextNoPassword = ({ setLoginType }: FooterTextProps) => (
  <p className="mt-3 mb-6 text-sm">
    Sie haben kein Passwort?{' '}
    <span className="text-cyan hover:text-cyan-900">
      <button onClick={() => setLoginType && setLoginType('new-password')}>Jetzt erstellen.</button>
    </span>
  </p>
);

interface FooterTextProps {
  setLoginType?: (loginType: AuthenticationType) => void;
}
