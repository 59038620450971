interface NavigationIconWithTextProps {
  iconText: string;
  icon: JSX.Element;
}

export const NavigationIconWithText = ({ iconText, icon }: NavigationIconWithTextProps) => {
  return (
    <>
      <div className="xs:hidden block">
        <div className="flex items-center">{icon}</div>
      </div>
      <div className="xs:block hidden lg:hidden">
        <div className="grid grid-cols-1 justify-items-center gap-y-0.5">
          {icon}
          <p className="text-sm">{iconText}</p>
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="flex items-center">
          {icon}
          <p className="ml-2 text-base">{iconText}</p>
        </div>
      </div>
    </>
  );
};
