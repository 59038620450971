import {
  Button,
  DropdownField,
  InputField,
  MessageBanner,
  OpenMailIcon,
  Spinner,
  salutationOptions,
  validationMessages,
} from '@dtk/ui-components';
import { MailIcon } from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';

export const REGISTRATION_FORM_LOADING_DURATION = 2 * 1000;

const credentialsSchema = object().shape({
  salutation: string().required(validationMessages.error.defaultDropdownField.text),
  lastname: string().required(validationMessages.error.defaultInputField.text),
  email: string().email(validationMessages.error.email.text).required(validationMessages.error.defaultInputField.text),
});

export interface RegistrationCustomerInfoFields {
  salutation: string;
  lastname: string;
  email: string;
}

export const RegistrationCredentialsForm = ({
  isRegistrationRequested,
  onSubmit,
  errorMessage = '',
}: RegistrationCredentialsFormProps) => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(true);

  const onClickChangeForm = () => {
    setIsFormLoading(true);
    setTimeout(() => {
      setIsFormLoading(false);
    }, REGISTRATION_FORM_LOADING_DURATION);
  };

  return isRegistrationRequested ? (
    isFormLoading ? (
      <Spinner />
    ) : (
      <div className="text-center">
        <div className="flex justify-center">
          <OpenMailIcon />
        </div>
        <div className="space-y-4 py-4">
          <p>
            Bestätigen Sie zeitnah ihren zugesendeten Link, um Zugang zu Ihrem persönlichen Kundenkonto zu erhalten.
            Schließen Sie dieses Fenster nicht.
          </p>
          <p>
            Sie haben keine E-Mail bekommen? Schauen Sie in Ihrem Spam-Ordner nach oder lassen Sie sich einen neuen Link
            schicken.
          </p>
          <Button
            data-testid="resendRegistrationLink"
            fullWidth
            type="submit"
            variant="navy"
            onClick={onClickChangeForm}
          >
            Link erneut senden
          </Button>
        </div>
      </div>
    )
  ) : (
    <Formik<RegistrationCustomerInfoFields>
      initialValues={{ salutation: '', lastname: '', email: '' }}
      validationSchema={credentialsSchema}
      onSubmit={(values) => {
        setShowButton(false);
        onSubmit(values);
      }}
      validateOnChange={false}
    >
      <Form className="space-y-6">
        <MessageBanner message={errorMessage} isError />
        <DropdownField aria-label="salutation" name="salutation" label="Anrede" options={salutationOptions} />
        <InputField data-testid="lastname" name="lastname" type="text" label="Nachname" />
        <InputField
          data-testid="email"
          placeholder="max@mustermann.de"
          name="email"
          type="text"
          label="Ihre E-Mail-Adresse"
          autoComplete="username"
          icon={MailIcon}
          infoMessage={validationMessages.info.email}
        />
        {showButton ? (
          <Button fullWidth type="submit" data-testid="submit" variant="navy">
            Absenden
          </Button>
        ) : (
          <Spinner />
        )}
      </Form>
    </Formik>
  );
};

export interface RegistrationCredentialsFormProps {
  isRegistrationRequested: boolean;
  onSubmit: (values: RegistrationCustomerInfoFields) => void;
  errorMessage?: string;
}
