/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieObjektinformationenHeizungen =
  | 'Einzelöfen'
  | 'Fußbodenheizung'
  | 'Gebäude- od. Wohnungszentralhzg.'
  | 'Sonstige (z.B. Nachtspeicherheizung)';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieObjektinformationenHeizungen = {
  Einzelfen: 'Einzelöfen' as ImmobilieObjektinformationenHeizungen,
  Fubodenheizung: 'Fußbodenheizung' as ImmobilieObjektinformationenHeizungen,
  'Gebude-_od_Wohnungszentralhzg': 'Gebäude- od. Wohnungszentralhzg.' as ImmobilieObjektinformationenHeizungen,
  Sonstige_zB_Nachtspeicherheizung: 'Sonstige (z.B. Nachtspeicherheizung)' as ImmobilieObjektinformationenHeizungen,
};
