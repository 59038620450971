import { StoryblokComponent } from '@storyblok/react';
import { useStoryblok } from '../storyblok';

export const SbComponentWithBridge = ({ ...props }) => {
  const { blok } = props;

  const enableBridge = true; // load the storyblok bridge everywhere
  // use the preview variable to enable the bridge only in preview mode
  // const enableBridge = preview;
  const storyWithEnabledBridge = useStoryblok(blok, enableBridge);

  return <StoryblokComponent blok={storyWithEnabledBridge?.content} />;
};
