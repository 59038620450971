/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

/**
 * custom error constant for, that available if dtkIndex max value is not available
 */
export type ProposalEstateValueIncreaseError = 'ENTITY_NOT_FOUND';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProposalEstateValueIncreaseError = {
  ENTITY_NOT_FOUND: 'ENTITY_NOT_FOUND' as ProposalEstateValueIncreaseError,
};
