import { Customer } from '@dtk/query';
import { AuthenticationCardFooter, AuthenticationHeaderText, useMediaQuery } from '@dtk/ui-components';
import { Dialog, Transition } from '@headlessui/react';
import { ShieldCheckIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { NewPasswordDialogState, PasswordPhase, getCurrentState } from '../../newPasswordTypes';
import { NewPasswordForm } from '../NewPasswordForm';

interface NewPasswordModalProps {
  customerData: Customer;
}

export const NewPasswordModal = ({ customerData }: NewPasswordModalProps) => {
  const { largerXs } = useMediaQuery();
  const [isModalOpen, setIsModalOpen] = useState(!customerData.hasPortalPassword);
  const [passwordPhase, setPasswortPhase] = useState<PasswordPhase>('SET_PASSWORD_REGISTRATION');
  const [state, setState] = useState<NewPasswordDialogState>(getCurrentState(passwordPhase));

  useEffect(() => {
    if (passwordPhase === 'SET_EMAIL') {
      setPasswortPhase('SET_PASSWORD');
    }
    if (passwordPhase === 'ENTER_CODE') {
      setPasswortPhase('ENTER_CODE_REGISTRATION');
    }
    if (passwordPhase === 'SUCCESS') {
      setPasswortPhase('SUCCESS_REGISTRATION');
      setTimeout(() => {
        setIsModalOpen(false);
      }, 2000);
    }
    setState(getCurrentState(passwordPhase, customerData.eMail));
  }, [passwordPhase, customerData.eMail]);

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10" onClose={() => undefined}>
        <div
          className="fixed inset-x-0 top-[3.75rem] bottom-0 bg-black bg-opacity-50 lg:top-[7.5rem]"
          aria-hidden="true"
        />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed bg-black" />
        </Transition.Child>

        <div className="fixed inset-x-0 top-[7.5rem] bottom-0 flex w-screen items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all">
              <div className="text-center">
                <AuthenticationHeaderText
                  overline={state.overline}
                  headline={state.headline}
                  subtitle={largerXs ? state.subtitle : ''}
                />
              </div>
              {passwordPhase === 'SUCCESS_REGISTRATION' ? (
                <div className="flex justify-center pt-2.5 text-green-500">
                  <ShieldCheckIcon className="h-12 w-12" />
                </div>
              ) : (
                <>
                  <div className="pb-6" />
                  <div className="py-2.5">
                    <NewPasswordForm
                      email={customerData.eMail}
                      passwordPhase={passwordPhase}
                      setPasswordPhase={setPasswortPhase}
                      customerData={customerData}
                    />
                    <AuthenticationCardFooter variant={state.footerVariant} setLoginType={() => undefined} />
                  </div>
                </>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
