/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Document Service
 * A service for displaying and modifying documents uploaded by the customer
 * OpenAPI spec version: 2021-11-17T08:07:55Z
 */

export type DocumentStatus = 'pending' | 'in_progress' | 'verified' | 'failed';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentStatus = {
  pending: 'pending' as DocumentStatus,
  in_progress: 'in_progress' as DocumentStatus,
  verified: 'verified' as DocumentStatus,
  failed: 'failed' as DocumentStatus,
};
