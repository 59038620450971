import classNames from 'classnames';
import { Field, FieldHookConfig, useField } from 'formik';

export interface RadioButton {
  id: string;
  title: string;
}

export interface RadioButtonProps {
  buttons: RadioButton[];
  name: string;
  headline?: string;
  isAlignedInRows?: boolean;
}

export function RadioButtons(
  props: React.HTMLProps<HTMLInputElement> & FieldHookConfig<string | number | unknown> & RadioButtonProps
) {
  const { buttons, name, headline, isAlignedInRows, ...restProps } = props;
  const [field, meta] = useField(name);

  return (
    <div className="font-body text-navy m-2">
      {headline && <p className="pb-2 text-lg font-medium">{headline}</p>}
      <fieldset>
        <legend className="sr-only">{name}</legend>
        <div className={headline || isAlignedInRows ? 'flex flex-row space-x-2' : 'space-y-4'}>
          {buttons.map((button) => (
            <div key={button.id} className="flex items-center">
              <Field
                {...field}
                {...restProps}
                id={button.id}
                data-testid={`${button.id}-radio`}
                name={name}
                value={button.id}
                type="radio"
                className={classNames(
                  'h-4 w-4 text-cyan-700 focus:outline-0 focus:outline-offset-0 focus:ring-0 focus:ring-offset-cyan-200',
                  meta.error && meta.touched
                    ? 'border-red-secondary focus:border-red-secondary border-2'
                    : 'border-navy'
                )}
              />
              <label htmlFor={button.id} className="ml-3 block text-base font-medium">
                {button.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {meta.touched && meta.error && (
        <div className="text-red-secondary font-body-semibold mt-2 text-sm">{meta.error}</div>
      )}
    </div>
  );
}
