/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieBauweiseBauweise = 'Massiv' | 'Fertighaus (massiv)' | 'Fertighaus (Holz)';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieBauweiseBauweise = {
  Massiv: 'Massiv' as ImmobilieBauweiseBauweise,
  Fertighaus_massiv: 'Fertighaus (massiv)' as ImmobilieBauweiseBauweise,
  Fertighaus_Holz: 'Fertighaus (Holz)' as ImmobilieBauweiseBauweise,
};
