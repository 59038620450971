import React, { useEffect, useState } from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { LoadingComponent } from '@dtk/public-website-ui-components';
import classNames from 'classnames';
import { storyBlokLoader } from '../../storyblok-loader';
import { storyblokEditable } from '@storyblok/react';
import { useRouter } from 'next/router';

const MP_ValidierungBlok = ({ ...props }) => {
  const router = useRouter();
  const { blok } = props;
  const [ccvalue, setValue] = useState(0);

  const forwardUrl = () => {
    router.push('checkout');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((val) => {
        if (val <= 100) {
          return val + 1;
        }
        forwardUrl();
        window.scrollTo({ top: 0, left: 0 });
        return 0;
      });
    }, blok.duration);
    return () => clearInterval(interval);
  }, [blok.duration]);

  return (
    <div
      className="bg-coolGray-50  relative flex w-full flex-row "
      {...storyblokEditable(blok)}
      data-test="mp-validierung"
      data-step-id={blok.gtmId}
    >
      <div
        className={'-mt-8 flex min-h-screen w-full p-5 md:p-10 ' + (blok?.image?.filename ? 'lg:w-2/3' : 'lg:w-3/3')}
      >
        <div className={classNames('align-center mx-auto my-auto  max-w-xl ')}>
          <LoadingComponent
            ccvalue={ccvalue}
            title={blok.title}
            subtitle={blok.subtitle}
            firstCheckingText={blok.firstCheckingText}
            secondCheckingText={blok.secondCheckingText}
            thirdCheckingText={blok.thirdCheckingText}
          />
          <h1 className="font-headline text-center text-4xl">Ihre eingegebenen Daten werden validiert ...</h1>
          <p className="my-3 text-center text-lg">
            Fusce risus nisl, viverra et, tempor et, pretium in, sapien. Etiam rhoncus.
          </p>
          <hr className="my-5" />
          <div className="my-3 flex justify-center gap-5">
            <div className="flex items-center justify-center">
              Lorem Ipsum <CheckIcon className="text-navy-400" width="20" height="20" />
            </div>
            <div className="flex items-center justify-center">
              Lorem Ipsum <CheckIcon className="text-navy-400" width="20" height="20" />
            </div>
            <div className="flex items-center justify-center">
              Lorem Ipsum <CheckIcon className="text-navy-400" width="20" height="20" />
            </div>
          </div>
        </div>
      </div>

      {blok?.image?.filename && (
        <div className="relative w-1/3">
          <Image
            src={blok.image.filename}
            loader={storyBlokLoader}
            alt={blok.image.alt}
            className=""
            layout="fill"
            objectFit="cover"
            priority={true}
          />
        </div>
      )}
    </div>
  );
};

export default MP_ValidierungBlok;
