import { Spinner } from '@dtk/ui-components';
import { useRouter } from 'next/router';
import { AuthenticationDialog } from '../../components/AuthenticationDialog';
import { AuthConfig } from '../../hooks';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { AuthState } from '../../types';

export interface AuthenticationProviderProps {
  config: AuthConfig;
}

function AuthenticationProvider({ children, config }: React.PropsWithChildren<AuthenticationProviderProps>) {
  const user = useCurrentUser();
  const router = useRouter();

  if (router.pathname === '/welcome' || router.pathname === '/abgemeldet') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return [AuthState.SignedOut, AuthState.SignedIn, AuthState.SignUp].includes(user.authState) ? (
    <AuthenticationDialog authState={user.authState} config={config}>
      {children}
    </AuthenticationDialog>
  ) : (
    <Spinner />
  );
}

export default AuthenticationProvider;
