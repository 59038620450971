/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieAusstattungAbstellraum =
  | 'Keiner'
  | 'innerhalb der Wohnung'
  | 'außerhalb der Wohnung'
  | 'innerhalb und außerhalb der Wohnung';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieAusstattungAbstellraum = {
  Keiner: 'Keiner' as ImmobilieAusstattungAbstellraum,
  innerhalb_der_Wohnung: 'innerhalb der Wohnung' as ImmobilieAusstattungAbstellraum,
  auerhalb_der_Wohnung: 'außerhalb der Wohnung' as ImmobilieAusstattungAbstellraum,
  innerhalb_und_auerhalb_der_Wohnung: 'innerhalb und außerhalb der Wohnung' as ImmobilieAusstattungAbstellraum,
};
