import { AuthenticationType, FooterVariant, SecondaryButton } from '@dtk/ui-components';

export interface NewPasswordDialogProps {
  setLoginType: (loginType: AuthenticationType) => void;
  email?: string;
}

export interface NewPasswordDialogState {
  overline: string;
  headline: string;
  subtitle: string;
  footerVariant: FooterVariant;
  secondaryButton?: SecondaryButton | undefined;
}

export type PasswordPhase =
  | 'SET_EMAIL'
  | 'SET_PASSWORD'
  | 'SET_PASSWORD_REGISTRATION'
  | 'ENTER_CODE'
  | 'ENTER_CODE_REGISTRATION'
  | 'SUCCESS'
  | 'SUCCESS_REGISTRATION'
  | 'ERROR';

export const getCurrentState = (phase: PasswordPhase, email?: string) => {
  const CONTENT: Record<PasswordPhase, NewPasswordDialogState> = {
    SET_EMAIL: {
      overline: 'Anmeldung',
      headline: 'Anmeldung zu Ihrem Kundenportal',
      subtitle: 'Geben Sie Ihre Email ein.',
      footerVariant: 'none',
      secondaryButton: { text: 'Abbrechen', loginType: 'credentials' },
    },
    SET_PASSWORD: {
      overline: 'Anmeldung',
      headline: 'Anmeldung zu Ihrem Kundenportal',
      subtitle: 'Legen Sie ein neues Passwort fest.',
      footerVariant: 'none',
    },
    SET_PASSWORD_REGISTRATION: {
      overline: 'Registrierung & Anmeldung',
      headline: 'Neues Passwort vergeben',
      subtitle: 'Legen Sie jetzt ein Passwort fest mit dem Sie sich bei nächsten Mal direkt anmelden können.',
      footerVariant: 'no-password',
    },
    ENTER_CODE: {
      overline: 'Verifizierung',
      headline: 'Wir haben Ihnen einen Code gesendet',
      subtitle: `Geben Sie den Code unten zur Verifizierung von ${email} ein.`,
      footerVariant: 'none',
    },
    ENTER_CODE_REGISTRATION: {
      overline: 'Verifizierung',
      headline: 'Wir haben Ihnen einen Code gesendet',
      subtitle: `Geben Sie den Code unten zur Verifizierung von ${email} ein.`,
      footerVariant: 'none',
    },
    SUCCESS: {
      overline: 'Verifizierung',
      headline: 'Passwort erfolgreich geändert',
      subtitle: 'Melden Sie sich mit Ihrem neuen Passwort an.',
      footerVariant: 'none',
      secondaryButton: { text: 'Mit Passwort anmelden', loginType: 'credentials', variant: 'navy' },
    },
    SUCCESS_REGISTRATION: {
      overline: 'Verifizierung',
      headline: 'Passwort erfolgreich geändert!',
      subtitle: '',
      footerVariant: 'none',
    },
    ERROR: {
      overline: 'Fehler',
      headline: 'Technischer Fehler',
      subtitle: 'Bitte wiederholen Sie Ihren Vorgang.',
      footerVariant: 'none',
    },
  };

  return CONTENT[phase];
};
