/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieModernisierungModernisierungDach = 'Keine' | 'Teilweise' | 'Komplett';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieModernisierungModernisierungDach = {
  Keine: 'Keine' as ImmobilieModernisierungModernisierungDach,
  Teilweise: 'Teilweise' as ImmobilieModernisierungModernisierungDach,
  Komplett: 'Komplett' as ImmobilieModernisierungModernisierungDach,
};
