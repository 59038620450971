import { Card, FaqDisclosure, QuestionAnswerData } from '@dtk/ui-components';

export function LoginFAQCard() {
  const faqs: QuestionAnswerData[] = [
    {
      question: 'Wie erhalte ich meinen persönlichen Zugang?',
      answer:
        'Sie erhalten Ihren persönlichen Zugang, indem Sie ein unverbindliches Angebot für Ihre Immobilie anfordern oder sich in unserem Kundenportal registrieren. Im Anschluss erhalten Sie eine E-Mail mit Ihrem persönlichen Zugang.',
    },
    {
      question: 'Benötige ich ein Passwort?',
      answer:
        'Wenn Sie sich mit Ihrer E-Mail-Adresse für Ihren persönlichen Bereich anmelden möchten, benötigen Sie grundsätzlich kein Passwort. Falls Sie eine Passwort-Anmeldung bevorzugen, können Sie aber jederzeit ein Passwort für Ihre Anmeldung erstellen.',
    },
    {
      question: 'Ihr Anmeldelink ist abgelaufen?',
      answer:
        'Das ist kein Problem. Geben Sie einfach erneut Ihre E-Mail-Adresse ein und wir senden Ihnen einen neuen Link zu, mit dem Sie sich anmelden können.',
    },
    {
      question: 'Sie haben die falsche E-Mail Adresse angegeben?',
      answer:
        'Bitte kontaktieren Sie in diesem Fall Ihren Teilkaufberater bei der Deutschen Teilkauf. Er wird Ihnen gerne weiterhelfen.',
    },
    {
      question: 'Wozu benötige ich einen persönlichen Zugang?',
      answer:
        'Dies ist notwendig, um auf alle Funktionen in Ihrem persönlichen Bereich zugreifen zu können. Auf diese Weise werden Ihre persönlichen Daten geschützt.',
    },
  ];

  return (
    <Card className="mt-4" headline="Häufige Fragen">
      <FaqDisclosure questionAnswerData={faqs} />
    </Card>
  );
}
