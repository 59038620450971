interface AuthenticationHeaderTextProps {
  overline: string;
  headline: string;
  subtitle: string;
}

export const AuthenticationHeaderText = ({ overline, headline, subtitle }: AuthenticationHeaderTextProps) => {
  return (
    <div className="space-y-1">
      <div className="text-gold text-sm uppercase tracking-[.2em]">{overline}</div>
      <h1 className="text-navy font-headline text-2xl leading-normal md:text-3xl">{headline}</h1>
      <p className="text-navy text-lg">{subtitle}</p>
    </div>
  );
};
