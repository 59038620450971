import CategoryOverviewPageBlok from './CategoryOverviewPageBlok';
import CategoryPostBlok from './CategoryPostBlok';
import MP_CheckoutBlok from './MP_CheckoutBlok';
import MP_ValidierungBlok from './MP_ValidierungBlok';
import ProductPostBlok from './ProductPostBlok';

export const marktplatzSbComponents = {
  CategoryOverviewPage: CategoryOverviewPageBlok,
  CategoryBlogPost: CategoryPostBlok,
  CategoryPost: CategoryPostBlok,
  MP_Checkout: MP_CheckoutBlok,
  MP_Validierung: MP_ValidierungBlok,
  ProductPost: ProductPostBlok,
};
