import { ViewGridIcon, SparklesIcon } from '@heroicons/react/outline';
import { NavigationRoutesProps } from '@dtk/ui-components';

export const clubRoutes: NavigationRoutesProps[] = [
  {
    href: '/dtk-club/uebersicht',
    name: 'Übersicht',
    icon: <ViewGridIcon className="h-6 w-6" />,
  },
  {
    href: '/dtk-club/top-angebot',
    name: 'Top-Angebot der Woche',
    icon: <SparklesIcon className="h-6 w-6" />,
  },
];
