/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Document Service
 * A service for displaying and modifying documents uploaded by the customer
 * OpenAPI spec version: 2021-11-17T08:07:55Z
 */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';
import type {
  DocumentList,
  Error,
  DeleteCustomerdocumentsDocumentsId200,
  SalesPartnerCustomerList,
  SalesPartnerCustomer,
  Form,
  Base64Pdf,
  PdfEncryptionRequest,
  Order,
} from '../../model/customer-documents';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * Return a list of all documents the current user has uploaded.
 */
export const getCustomerdocumentsDocuments = (options?: AxiosRequestConfig): Promise<AxiosResponse<DocumentList>> => {
  return axios.get(`/customer-documents/documents`, options);
};

export const getGetCustomerdocumentsDocumentsQueryKey = () => [`/customer-documents/documents`];

export const useGetCustomerdocumentsDocuments = <
  TData = AsyncReturnType<typeof getCustomerdocumentsDocuments>,
  TError = AxiosError<Error>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getCustomerdocumentsDocuments>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerdocumentsDocumentsQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getCustomerdocumentsDocuments>> = () =>
    getCustomerdocumentsDocuments(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getCustomerdocumentsDocuments>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * Download the document with the given id. A user may only download their own documents. If the authenticated user is a sales partner they are allowed to download the documents of their customers.
 */
export const getCustomerdocumentsDocumentsId = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.get(`/customer-documents/documents/${id}`, options);
};

export const getGetCustomerdocumentsDocumentsIdQueryKey = (id: string) => [`/customer-documents/documents/${id}`];

export const useGetCustomerdocumentsDocumentsId = <
  TData = AsyncReturnType<typeof getCustomerdocumentsDocumentsId>,
  TError = AxiosError<Error>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getCustomerdocumentsDocumentsId>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerdocumentsDocumentsIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getCustomerdocumentsDocumentsId>> = () =>
    getCustomerdocumentsDocumentsId(id, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getCustomerdocumentsDocumentsId>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Delete the document with the given id. If the document was not found a 404 is returned.
 */
export const deleteCustomerdocumentsDocumentsId = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DeleteCustomerdocumentsDocumentsId200>> => {
  return axios.delete(`/customer-documents/documents/${id}`, options);
};

export const useDeleteCustomerdocumentsDocumentsId = <TError = AxiosError<void | Error>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteCustomerdocumentsDocumentsId>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteCustomerdocumentsDocumentsId>, { id: string }> = (
    props
  ) => {
    const { id } = props || {};

    return deleteCustomerdocumentsDocumentsId(id, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof deleteCustomerdocumentsDocumentsId>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * Get a list of all customers the authenticated sales partner manages
 */
export const getCustomerdocumentsSalespartnersCustomers = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SalesPartnerCustomerList>> => {
  return axios.get(`/customer-documents/salespartners/customers`, options);
};

export const getGetCustomerdocumentsSalespartnersCustomersQueryKey = () => [
  `/customer-documents/salespartners/customers`,
];

export const useGetCustomerdocumentsSalespartnersCustomers = <
  TData = AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomers>,
  TError = AxiosError<void | Error>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomers>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerdocumentsSalespartnersCustomersQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomers>> = () =>
    getCustomerdocumentsSalespartnersCustomers(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomers>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * Get a single customer
 */
export const getCustomerdocumentsSalespartnersCustomersId = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SalesPartnerCustomer>> => {
  return axios.get(`/customer-documents/salespartners/customers/${id}`, options);
};

export const getGetCustomerdocumentsSalespartnersCustomersIdQueryKey = (id: string) => [
  `/customer-documents/salespartners/customers/${id}`,
];

export const useGetCustomerdocumentsSalespartnersCustomersId = <
  TData = AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersId>,
  TError = AxiosError<void | Error>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersId>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerdocumentsSalespartnersCustomersIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersId>> = () =>
    getCustomerdocumentsSalespartnersCustomersId(id, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersId>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * Return documents of a customer
 */
export const getCustomerdocumentsSalespartnersCustomersIdDocuments = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DocumentList>> => {
  return axios.get(`/customer-documents/salespartners/customers/${id}/documents`, options);
};

export const getGetCustomerdocumentsSalespartnersCustomersIdDocumentsQueryKey = (id: string) => [
  `/customer-documents/salespartners/customers/${id}/documents`,
];

export const useGetCustomerdocumentsSalespartnersCustomersIdDocuments = <
  TData = AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersIdDocuments>,
  TError = AxiosError<void | Error>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersIdDocuments>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerdocumentsSalespartnersCustomersIdDocumentsQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersIdDocuments>> = () =>
    getCustomerdocumentsSalespartnersCustomersIdDocuments(id, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getCustomerdocumentsSalespartnersCustomersIdDocuments>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary generates and returns PDF document based on input fields
 */
export const postCustomerdocumentsPdfPreview = (
  form: Form,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Blob>> => {
  const formData = new FormData();
  formData.append('signature', form.signature);
  formData.append('input', form.input);

  return axios.post(`/customer-documents/pdf/preview`, formData, {
    responseType: 'blob',
    ...options,
  });
};

export const usePostCustomerdocumentsPdfPreview = <TError = AxiosError<Error>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postCustomerdocumentsPdfPreview>,
    TError,
    { data: Form },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof postCustomerdocumentsPdfPreview>, { data: Form }> = (
    props
  ) => {
    const { data } = props || {};

    return postCustomerdocumentsPdfPreview(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof postCustomerdocumentsPdfPreview>, TError, { data: Form }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary protects pdf from s3 with password and returns a base64 encoded string
 */
export const postCustomerdocumentsPdfEncrypt = (
  pdfEncryptionRequest: PdfEncryptionRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Base64Pdf>> => {
  return axios.post(`/customer-documents/pdf/encrypt`, pdfEncryptionRequest, options);
};

export const usePostCustomerdocumentsPdfEncrypt = <TError = AxiosError<Error>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postCustomerdocumentsPdfEncrypt>,
    TError,
    { data: PdfEncryptionRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postCustomerdocumentsPdfEncrypt>,
    { data: PdfEncryptionRequest }
  > = (props) => {
    const { data } = props || {};

    return postCustomerdocumentsPdfEncrypt(data, axiosOptions);
  };

  return useMutation<
    AsyncReturnType<typeof postCustomerdocumentsPdfEncrypt>,
    TError,
    { data: PdfEncryptionRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary create order to request documents from partner
 */
export const postCustomerdocumentsOrders = (
  order: Order,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/customer-documents/orders`, order, options);
};

export const usePostCustomerdocumentsOrders = <TError = AxiosError<Error>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof postCustomerdocumentsOrders>, TError, { data: Order }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof postCustomerdocumentsOrders>, { data: Order }> = (
    props
  ) => {
    const { data } = props || {};

    return postCustomerdocumentsOrders(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof postCustomerdocumentsOrders>, TError, { data: Order }, TContext>(
    mutationFn,
    mutationOptions
  );
};
