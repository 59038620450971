import { CognitoUser } from '@aws-amplify/auth';
import { log } from '@dtk/logging';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useAuthState } from '../store/useAuthState';
import { AuthState, CurrentUser } from '../types';

export const NOT_LOGGED_IN_STATE = { authState: AuthState.SignUp };
const COGNITO_REFRESH_INTERVAL_SECONDS = 60;
let tokenRefreshJob: ReturnType<typeof globalThis.setInterval>;

export const useCurrentUser = (): CurrentUserType => {
  const authState = useAuthState();

  async function updateCurrentUser(bypassCache = false) {
    try {
      const authData: CognitoUser = await Auth.currentAuthenticatedUser({ bypassCache });
      if (!authData) {
        log.debug('User is not set, setting state to logged out');
        authState.setLoggedOut();
      }
      const cognitoUser = authData.getSignInUserSession();
      log.debug('Got currentUser from Cognito');
      if (!cognitoUser) {
        log.debug('User is not set, setting state to logged out');
        authState.setLoggedOut();
      }
      log.debug({ userName: authData.getUsername() }, 'Logged in successfully');

      authState.setCurrentUser({
        name: authData.getUsername(),
        accessToken: cognitoUser?.getAccessToken().getJwtToken(),
      });
    } catch (e) {
      log.warn({ error: String(e) }, 'Got error from Cognito');
      authState.setLoggedOut();
    }
  }

  useEffect(() => {
    // we only want one global interval
    clearInterval(tokenRefreshJob);
    if (authState.authState === AuthState.SignedIn) {
      tokenRefreshJob = setInterval(() => {
        updateCurrentUser(true).catch(() => clearInterval(tokenRefreshJob));
      }, COGNITO_REFRESH_INTERVAL_SECONDS * 1000);
    }
    return () => {
      clearInterval(tokenRefreshJob);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.authState]);

  useEffect(() => {
    updateCurrentUser().catch((e) => {
      log.warn({ error: (e as Error).message }, 'Could not fetch user, transitioning to logged out');
      authState.setLoggedOut();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(authState.config), authState.lastChange]);

  return authState;
};

export interface CurrentUserType {
  user?: CurrentUser;
  authState: AuthState;
}
