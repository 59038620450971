/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieEnergieeffizienzEnergieeffizienzKlasse =
  | 'k.A.'
  | 'Aplus'
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieEnergieeffizienzEnergieeffizienzKlasse = {
  kA: 'k.A.' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  Aplus: 'Aplus' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  A: 'A' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  B: 'B' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  C: 'C' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  D: 'D' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  E: 'E' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  F: 'F' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  G: 'G' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  H: 'H' as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
};
