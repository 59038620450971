import { LoginFAQCard } from './LoginFAQ';
import { Container, AuthenticationHeaderText } from '@dtk/ui-components';

export const LoginHelp = () => (
  <Container className="mt-8 space-y-8" backgroundColor="coolGray-100">
    <div className="text-center">
      <AuthenticationHeaderText
        overline="Häufige Fragen"
        headline="Sie benötigen Hilfe beim Login?"
        subtitle="Hier haben wir Ihnen die häufigsten Fragen zur Anmeldung und Registrierung zusammengestellt."
      />
      <LoginFAQCard />
    </div>
  </Container>
);
