import classNames from 'classnames';
import React from 'react';

import styles from './RadialGauge.module.css';
import { tailwindClassToThemeValue } from '../../hooks';

export interface RadialGaugeProps {
  percent: number;
  children?: React.ReactNode;
  isFullCircle?: boolean;
  contentStyle?: string;
  svgPrimaryColor?: string;
  svgSecondaryColor?: string;
}

const radiusDashedCircle = 100;
const radiusFullCircle = 90;

export const RadialGauge = ({
  percent,
  children,
  contentStyle,
  isFullCircle = false,
  svgPrimaryColor,
  svgSecondaryColor,
}: RadialGaugeProps) => {
  const radius = isFullCircle ? radiusFullCircle : radiusDashedCircle;
  const rotate = isFullCircle ? -90 : 144;
  const arcAngle = isFullCircle ? 360 : 252;

  const strokeWidth = radius * 0.08;
  const innerRadius = radius - strokeWidth / 2;

  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * (arcAngle / 360);
  const dashArray = `${arc} ${circumference}`;

  const transform = `rotate(${rotate}, ${radius}, ${radius})`;

  const percentNormalized = Math.min(Math.max(percent, 0), 100);
  const offsetDashedCircle = arc - (percentNormalized / 100) * arc;
  const offsetFullCircle = arc + (percentNormalized / 100) * arc;

  const offset = isFullCircle ? offsetFullCircle : offsetDashedCircle;

  return (
    <div className="relative inline-block w-auto ">
      <div className={classNames(styles.gaugeInnerText, isFullCircle && styles.gaugeCenter)}>
        <div className={classNames('text-3md h-full font-bold', contentStyle)}>{children}</div>
      </div>

      <div>
        <svg height={radius * 2} width={radius * 2}>
          <circle
            cx={radius}
            cy={radius}
            r={innerRadius}
            strokeWidth={strokeWidth}
            strokeDasharray={!isFullCircle ? dashArray : undefined}
            transform={transform}
            fill="transparent"
            strokeLinecap="round"
            stroke={svgSecondaryColor ? tailwindClassToThemeValue(svgSecondaryColor) : '#CCC'}
          />
          <circle
            cx={radius}
            cy={radius}
            r={innerRadius}
            strokeWidth={strokeWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={offset}
            transform={transform}
            fill="transparent"
            strokeLinecap="round"
            stroke={svgPrimaryColor ? tailwindClassToThemeValue(svgPrimaryColor) : 'gold-gold'}
          />
        </svg>
      </div>
    </div>
  );
};
