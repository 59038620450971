import classNames from 'classnames';

export interface CardContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const CardContainer = ({ children, className }: CardContainerProps) => {
  return (
    <div
      className={classNames(
        'shadow-toolbar rounded-md bg-white',
        'flex flex-grow flex-col',
        'px-2 py-3 lg:px-5 lg:py-6',
        className
      )}
    >
      {children}
    </div>
  );
};
