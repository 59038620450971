/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */

export type ImmobilieModernisierungModernisierungWaermedaemmung = 'Keine' | 'Teilweise' | 'Komplett';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImmobilieModernisierungModernisierungWaermedaemmung = {
  Keine: 'Keine' as ImmobilieModernisierungModernisierungWaermedaemmung,
  Teilweise: 'Teilweise' as ImmobilieModernisierungModernisierungWaermedaemmung,
  Komplett: 'Komplett' as ImmobilieModernisierungModernisierungWaermedaemmung,
};
