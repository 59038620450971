import { ExclamationIcon } from '@heroicons/react/solid';

export function Notification({ children }: { children: string }) {
  return (
    <div className="bg-gold-300 rounded-md p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="text-gold-800 h-5 w-5" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-gold-800 text-sm font-medium">{children}</h3>
        </div>
      </div>
    </div>
  );
}
