import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import React from 'react';

const CategoryOverviewPageBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div
      {...storyblokEditable(blok)}
      className="category-overview-page"
      key={blok._uid}
      data-test="category-overview-page"
    >
      {blok.body.map((nestedBlok: SbBlokData) => (
        <div key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))}
    </div>
  );
};

export default CategoryOverviewPageBlok;
