import { log } from '@dtk/logging';
import { useEffect, useState } from 'react';

export enum StreamState {
  INIT,
  ERROR,
  PENDING,
}

export const useEventSource = (source: URL, onMessage: MessageCallback) => {
  const url = source.toString();
  const messageKeys = Object.keys(onMessage);
  const [state, setState] = useState<EventStreamState>({ state: StreamState.INIT });

  useEffect(() => {
    const eventSource = new EventSource(url);
    messageKeys.forEach((messageName) => {
      eventSource.addEventListener(messageName, (ev: Event) => {
        const data = ev as MessageEvent;
        onMessage[messageName](data.data);
      });
    });

    eventSource.onopen = () => {
      setState({ state: StreamState.PENDING });
    };

    eventSource.onerror = (error) => {
      log.warn({ error: JSON.stringify(error) }, 'Error in EventSource');
      setState({
        error: 'Error',
        state: StreamState.ERROR,
      });
    };
    return () => {
      log.info('Closing event source');
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, messageKeys.join(',')]);

  return state;
};

export interface EventStreamState {
  state: StreamState;
  error?: string;
}
export type Callback<T> = { (message: T): void };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageCallback = Record<MessageName, Callback<any>>;
export type MessageName = string;
