import { Toaster, Toast as ToastProps, resolveValue, toast } from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';
import { IconCheckmark, IconDelete, IconError } from '../static';

export function NotificationProvider(props: ComponentPropsWithoutRef<typeof Toaster>) {
  return (
    <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} {...props}>
      {(t) => (
        <Transition show={t.visible}>
          <Toast {...t} />
        </Transition>
      )}
    </Toaster>
  );
}

function Toast(props: ToastProps) {
  const { message, type, id } = props;

  const iconProps = {
    width: 64,
    height: 64,
    className: 'my-2 mr-6',
  };

  return (
    <div
      className={classNames(
        'shadow-navigation h-32 w-80',
        'py-6 pl-6 pr-12',
        'text-base text-white',
        type === 'blank' && 'bg-cyan',
        type === 'success' && 'bg-green-500',
        type === 'error' && 'bg-red'
      )}
    >
      <IconDelete
        width={24}
        onClick={() => toast.dismiss(id)}
        className="absolute top-4 right-4 cursor-pointer fill-current"
      />
      <div className="flex">
        {type === 'success' && <IconCheckmark {...iconProps} />}
        {type === 'error' && <IconError {...iconProps} />}
        {resolveValue(message, props)}
      </div>
    </div>
  );
}

export { toast as notification } from 'react-hot-toast';
