import { MailIcon } from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import {
  Button,
  InputField,
  Timer,
  UseCountdownTimeProps,
  OpenMailIcon,
  Spinner,
  validationMessages,
  MessageBanner,
} from '@dtk/ui-components';
import labels from './MagicLinkRequestForm.labels';
import { MagicLinkRequestPhase } from '../MagicLink';

const MagicLinkSchema = object().shape({
  email: string().email(validationMessages.error.email.text).required(validationMessages.error.defaultInputField.text),
});

export interface MagicLinkFields {
  email: string;
}

export const MagicLinkForm = ({ onSubmit, phase, customErrorMsg = '', onTimerExpired }: MagicLinkFormProps) => {
  const countdown: UseCountdownTimeProps = {
    minutes: 15,
    seconds: 0,
  };
  return (
    <Formik<MagicLinkFields>
      initialValues={{ email: '' }}
      validationSchema={MagicLinkSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      <Form>
        {(phase === MagicLinkRequestPhase.INITIAL ||
          phase === MagicLinkRequestPhase.ERROR ||
          phase === MagicLinkRequestPhase.EXPIRED) && (
          <div className="space-y-6">
            <MessageBanner message={customErrorMsg} isError />
            <InputField
              data-testid="login-email"
              placeholder="max@mustermann.de"
              name="email"
              labelBold={true}
              type="text"
              label="Ihre E-Mail-Adresse"
              icon={MailIcon}
              infoMessage={validationMessages.info.email}
            />

            <Button data-testid="submitRequestLink" type="submit" variant="navy" fullWidth>
              {phase === MagicLinkRequestPhase.EXPIRED ? labels.resendRequestLink : labels.requestLink}
            </Button>
          </div>
        )}
        {phase === MagicLinkRequestPhase.REQUESTING ||
          (phase === MagicLinkRequestPhase.PENDING && (
            <div className="my-20">
              <Spinner />
            </div>
          ))}{' '}
        {phase === MagicLinkRequestPhase.WAITING_FOR_CHALLENGE && (
          <div className="text-center">
            <div className="flex justify-center">
              <OpenMailIcon />
            </div>
            <div className="space-y-4 py-4">
              <p>
                Der Link ist noch <Timer classNames="text-gold" countdown={countdown} handleCallback={onTimerExpired} />{' '}
                Minuten gültig. Bestätigen Sie diesen zeitnah, um Zugang zu Ihrem persönlichen Kundenkonto zu erhalten.
                Schließen Sie dieses Fenster nicht.
              </p>
              <p>
                Sie haben keine E-Mail bekommen? Schauen Sie in Ihrem Spam-Ordner nach oder lassen Sie sich einen neuen
                Link schicken.
              </p>
            </div>
            <div className="w-full">
              <Button data-testid="submitRequestLink" fullWidth type="submit" variant="navy">
                {labels.resendRequestLink}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </Formik>
  );
};

export interface MagicLinkFormProps {
  onSubmit: (values: MagicLinkFields) => void;
  phase: MagicLinkRequestPhase;
  customErrorMsg?: string;
  onTimerExpired: () => void;
}
